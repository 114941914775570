.phone-preview {
    background: #888;
    border-radius: 20px;
    padding: 20px 5px;

    .phone-preview-screen {
        background: #ffffff;
        height: 100%;
        border-radius: 4px;
        position: relative;

        .message-form-button {
            background: rgba(0, 177, 143, 0.2);
            color: #000; }

        .conversation-subject {
            font-size: 16px;
            font-weight: 500;
            height: 50px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            box-shadow: 0px 1px 3px rgba(0,0,0,0.05);
            border-bottom: 1px solid #eee; }

        .main-messaging-pane {
            background: #fafafa;
            overflow: hidden;
            position: relative;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none; }

            .pharmacy-icon {
                position: absolute;
                left: 5px;
                top: 5px;
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #EBF4FF;
                border: 1px solid #C1DDFF;
                border-radius: 50%;
                box-shadow: 2px 2px 4px rgba(0,0,0,0.1);

                .MuiSvgIcon-root {
                    color: #1A84FF;
                    margin-left: -1px; } }

            .message-bubbles {
                margin: 5px 15px 5px 40px;
                transition: transform 0.3s ease-in;

                .message-bubble {
                    background: #f3f3f3;
                    font-size: 11px;
                    margin-bottom: 10px;
                    padding: 10px;
                    border-radius: 6px;
                    box-shadow: 2px 2px 4px rgba(0,0,0,0.15);
                    border: 1px solid #eee;
                    opacity: 0;
                    transition: opacity 0.3s ease-in;

                    &.visible {
                        opacity: 1; } } } }




        .bottom-response-area {
            height: 50px;
            display: flex;
            padding: 0 10px;
            width: 100%;
            position: absolute;
            bottom: 0;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #eee;

            .camera-and-placeholder {
                display: inline-flex;
                align-items: center; }

            .MuiSvgIcon-root {
                font-size: 30px;
                color: #888888; }
            .placeholder {
                color: #555;
                margin-left: 10px; } } } }

.phone-preview-fullscreen {
    position: absolute;
    z-index: 10000;
    display: none;
    border-radius: 4px;
    overflow: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none; }

    .open-link-popup {
        width: 100%;
        height: 100%;
        background: #fff;
        font-family: Roboto, sans-serif;

        .heading {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 0 10px;

            .title {
                font-size: 16px;
                font-weight: 500; } }

        .main-message {
            font-size: 16px;
            color: #888;
            text-align: center;
            margin-top: 50px;
            padding: 0 10px;

            .link {
                margin: 10px 0;
                color: #1A84FF;
                font-size: 13px;
                overflow-wrap: break-word;
                word-wrap: break-word;
                width: 100%; } } } }

