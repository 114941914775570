.waitlist-scheduling-nav-bar {
    height: 55px;
    background: #444;
    display: flex;
    align-items: center;

    .waitlist-scheduling-tab {
        margin: 0 20px;
        display: flex;
        align-items: center;

        &.clickable {
            cursor: pointer; }

        .appt-block-main-text {
            display: inline-flex;

            .slot-count {
                font-size: 12px;
                font-weight: 400;
                margin-left: 10px; } }

        .appt-block-sub-text {
            font-size: 13px;
            font-weight: 500;

            .dose-counts {
                font-size: 12px;
                font-weight: 400;
                margin-left: 10px; } }

        .step-number {
            width: 28px;
            height: 28px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            color: #eee;
            background: #888;
            border-radius: 15px;
            margin-right: 10px; }

        .step-label {
            color: #eee;
            font-size: 15px;
            font-weight: 500;
            transition: all 0.2s; }

        .check-wrapper {
            background: #fff;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-right: 10px;
            .MuiSvgIcon-root {
                color: #00B18F;
                font-size: 28px; } }

        .completed-label {
            display: inline-flex;
            color: #fff;
            flex-direction: column;
            .main-line {
                font-size: 13px;
                font-weight: 500; }
            .sub-line {
                font-size: 11px;
                font-weight: 400; } }

        &.focused {
            cursor: default;
            .step-number {
                background: #1A84FF; }
            .step-label, .completed-label {
                color: #82BCFF; } } } }
