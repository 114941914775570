html {
    width: 100%;
    min-height: 100vh; }

body {
    min-height: calc(100vh - 80px); }

#root {
    min-height: calc(100vh - 80px); }

