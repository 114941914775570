.connect-management-root-page {
    max-width: 1200px;
    margin: auto;

    .heading {
        height: 50px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .title {
            font-size: 18px;
            font-weight: 500;
            width: 260px;
            text-align: center; }

        .subtitle {
            font-size: 12px;
            margin-left: 20px;
            width: calc(100% - 300px); } }

    .left-bar-and-content {
        display: flex;

        .left-bar {
            border-right: 1px solid #ddd;
            //background: #fafafa



            .MuiTabs-root {
                //border-top: 1px solid #ddd
                //border-bottom: 1px solid #ddd
                width: 260px;
                .MuiTab-root {
                    background: #fafafa;
                    transition: background 0.3s; }

                .Mui-selected {
                    background: #B4D7FE; } } }

        .connect-management-content {
            padding: 0 20px; } } }
