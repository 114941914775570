.patient-select-content {
    padding: 0 20px;
    .heading {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-size: 16px;
            font-weight: 500; } } }
