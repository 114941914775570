.confirm-message-page {
    .confirm-message-title-line {
        margin: auto;
        padding: 0 10px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .button-and-title {
            display: inline-flex;
            align-items: center; }

        .title {
            font-size: 20px;
            font-weight: 500;
            margin-left: 10px;
            padding: 5px 0;
            white-space: nowrap; }

        .subtitle {
            font-size: 14px;
            margin-left: 40px; } }

    .confirm-message-content {
        padding: 20px;
        margin: auto;

        .actions {
            display: flex;
            justify-content: flex-end;

            .cancel-button {
                margin-right: 20px; } } } }
