.patients-header-bar {
    position: relative;
    height: 40px;
    margin-bottom: 20px;
    margin-top: 10px;
    .main-line {
        display: flex;
        align-items: center;
        justify-content: space-between;


        .left {
            display: inline-flex;
            align-items: center;

            .go-back-button {
                margin-right: 10px; }

            .search-text-field {
                margin-right: 20px;
                .MuiOutlinedInput-adornedEnd {
                    padding-right: 6px; }
                .MuiInputBase-input {
                    padding: 12px 10px 12px 0; }

                .field-and-close {
                    display: flex;
                    align-items: center;
                    background: #B4D7FE;
                    border-radius: 4px;
                    padding: 4.5px 5px 4.5px 10px;
                    .clear-icon {
                        cursor: pointer; }

                    .field-being-queried {
                        font-size: 11px;
                        color: #333;
                        //background: #eee
                        line-height: 13px;
                        //padding: 4px 10px
 } } } }                        //border-radius: 10px

        .import-selection-list-link {
            color: #1A84FF;
            text-decoration: none;
            &:hover {
                text-decoration: underline; } }

        .import-selection-list-link[disabled] {
            color: rgba(0, 0, 0, 0.26);
            pointer-events: none; }

        .add-patients-button {
            background: #2F80ED;
            color: #fff; } }



    .suggested-name-option {
        position: absolute;
        top: calc(100% + 5px);
        font-size: 13px;
        font-weight: 500;
        .MuiLink-root {
            font-weight: 500; } }

    .keyboard-selection-instructions {
        position: absolute;
        width: 400px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: calc(100% + 5px);
        font-size: 12px;
        color: #555;
        white-space: nowrap;

        .unmessageable-notice {
            display: flex;
            align-items: center; }

        .alert-icon {
            font-size: 16px;
            color: red;
            margin-right: 3px; }
        .name {
            display: inline-block;
            font-weight: 500;
            color: #000;
            width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap; }

        .row-instructions {
            width: 280px;
            display: flex;
            align-items: center; }

        .nav-instructions {
            display: inline-flex;
            align-items: center;
            font-size: 12px;
            width: 120px;
            white-space: nowrap; } }

    .view-waitlists-button {
        color: #00B18F;
        border-color: #00B18F; } }
