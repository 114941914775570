.patient-appointments-dialog {

    .contact-header-bar {
        position: absolute;
        width: 100%;
        background: #fff;
        padding: 0;
        box-shadow: 0 2px 4px rgba(0,0,0,0.05);

        .buttons {
            margin-right: 15px;
            .add-button {
                margin-bottom: 10px;
                text-align: right; }

            .past-button, .upcoming-button {
                margin-left: 5px;
                font-weight: 400;
                padding: 4px 10px;
                border: 1px solid transparent;

                .count {
                    margin-left: 10px;
                    font-weight: 400;
                    background: #f6f7f8;
                    color: #1A84FF;
                    display: flex;
                    width: 24px;
                    height: 24px;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20px;
                    font-size: 11px;
                    transition: all 0.2s; } }

            .Mui-disabled {
                color: #000;
                background: rgba(26,132,255, 0.1);
                //box-shadow: 0px 1px 2px rgba(0,0,0,0.1)
                font-weight: 500;
                border: 1px solid rgba(26,132,255, 0.1);

                .count {
                    background: #00B18F;
                    color: #fff; } } } }


    .patient-appointments {
        margin-top: 96px;
        padding: 10px;
        overflow-y: scroll; } }
