.create-or-edit-appointment-dialog {
    font-family: Roboto, sans-serif;

    .confirm-delete-overlay {
        .buttons {
            text-align: right;
            margin-top: 10px; } }

    .appt-create-or-update-error-snackbar {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        background: #F0ACB0;
        box-shadow: 4px 4px 8px rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        padding: 10px 15px;
        .MuiSvgIcon-root {
            font-size: 32px;
            margin-right: 10px; }

        .message {
            width: 400px;
            font-size: 13px; } }

    .create-or-edit-appointment-content {
        display: flex;
        width: 870px;
        height: 670px;
        padding: 0 !important;

        .left-bar {
            width: 280px;
            background: #f7f8f9;
            border-right: 1px solid #e6e6e6;

            .heading {
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                color: #888888;
                font-weight: 500; }

            .nav-entries {
                background: #ffffff;
                border-top: 1px solid #e6e6e6; }

            .nav-bar-entry {
                position: relative;
                height: 80px;
                border-bottom: 1px solid #e6e6e6;
                display: flex;
                padding: 0 20px;
                align-items: center;
                cursor: pointer;
                transition: all 0.3s ease-in;

                .completed-bar {
                    position: absolute;
                    height: 80px;
                    width: 5px;
                    background: #00B18F;
                    top: 0;
                    left: 0; }

                .icon-wrapper {
                    margin-right: 10px;
                    box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
                    border-radius: 50%;
                    width: 50px;
                    height: 50px; }

                .image-ref-icon {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 1px solid #e6e6e6; }


                .labels {
                    max-height: 80px;
                    overflow: hidden;
                    .main-label {
                        font-size: 15px;
                        font-weight: 500;
                        flex: 1;
                        width: 180px;
                        display: inline;
                        display: -webkit-box;
                        word-wrap: break-word;
                        line-height: 20px;
                        /* autoprefixer: off */
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        text-overflow: ellipsis; }
                    .sub-label {
                        font-size: 11px;
                        color: #555; } }

                &.focused {
                    background: #EBF4FF;
                    cursor: default;

                    .labels {
                        .main-label, .sub-label {
                            color: #1A84FF; } } }

                &.disabled {
                    cursor: default;

                    .labels {
                        .main-label, .sub-label {
                            color: #aaa; } }
                    background: #fafafa; } }

            .delete-button-wrapper {
                display: flex;
                margin-top: 20px;
                justify-content: center;

                .delete-appt-button {
                    background: #fff; } } }

        .main-content {
            width: calc(100% - 280px);

            .buttons {
                position: absolute;
                bottom: 0;
                right: 0;
                padding: 10px 20px; } } } }


