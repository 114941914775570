.schedule-by-manual-select-content {
    padding: 10px 20px;
    position: relative;

    .heading {
        font-size: 16px;
        font-weight: 500;

        .prefix {
            margin-right: 10px; }

        .waitlist-button, .pharmacy-button {
            text-transform: none;
            font-size: 16px;
            margin: 0 5px; }

        .selected-button {
            color: #1A84FF;
            background: rgba(26, 132, 255, 0.1); } }


    .to-by-count-button {
        position: absolute;
        top: 60px;
        right: 20px;
        text-transform: none;
        color: #1A84FF;
        font-weight: 400;
        font-size: 14px; } }
