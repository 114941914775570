.waitlist-manage-content {
    .waitlist-manage-heading {
        font-size: 16px;
        font-weight: 500; } }

.confirm-delete-waitlist-patients-dialog {
    font-family: Roboto, sans-serif;

    h2, .MuiDialogContent-root, .cancel-button {
        color: #fff; }

    .MuiDialog-paper {
        background: #DF4851;

        .MuiDialogActions-root {
            padding: 10px 20px; } } }

