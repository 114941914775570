
.appointments-popover-custom-dates-dialog {
    font-family: Roboto, sans-serif;

    .MuiDialogTitle-root {
        padding-bottom: 10px; }

    .MuiDialogContent-root {
        width: 500px;

        .sub-heading {
            margin-bottom: 20px;
            font-size: 13px; }

        .calendars {
            display: flex;
            justify-content: space-between;

            .calendar-wrapper {
                border: 1px solid #e6e6e6;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;

                .calendar-heading {
                    height: 40px;
                    background: #f6f7f8;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 10px;
                    align-items: center;
                    font-size: 13px;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;

                    .date {
                        font-size: 15px;
                        font-weight: 500;
                        color: #1A84FF; } } } } } }
