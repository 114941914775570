.appointment-block-selector {
    position: relative;
    margin: 0 auto;
    min-height: 400px;

    .help-icon-and-text {
        font-size: 12px;
        display: inline-flex;
        align-items: center;
        font-weight: 400;

        .MuiSvgIcon-root {
            color: #1A84FF;
            margin-right: 3px; } }

    &.from-existing {
        max-width: 800px;
        margin-top: 60px;

        .loading-spinner {
            position: absolute;
            left: calc(50% - 22px); }

        .main-label {
            font-size: 17px;
            font-weight: 500;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between; }

        .sub-label {
            font-size: 13px;
            margin-bottom: 10px; }

        .appointment-blocks-list {
            border: 1px solid #e6e6e6;
            outline: none;

            .no-appointment-blocks-message {
                font-size: 14px;
                color: #555;
                height: 130px;
                margin: 0 auto;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .warning-message {
                    font-size: 15px;
                    font-weight: 500; }

                .main-no-blocks-message {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 18px;

                    > span {
                        height: 24px;
                        display: inline-flex;
                        align-items: center; }


                    .MuiButton-root {
                        text-transform: none;
                        font-size: 14px;
                        padding: 0px 6px;
                        font-weight: 400; } } } }



        .buttons-row {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;

            .MuiButton-root {
                margin-left: 20px; }


            .continue-without-selection-button {
                text-transform: none; } } }


    &.create-or-edit {
        padding: 20px;

        .main-label {
            font-size: 18px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .go-back-button {
                border: 1px solid #e6e6e6;
                padding: 6px;
                margin-right: 10px;
 }                //background: rgba(0,0,0,0.05)

            .sub-text {
                font-size: 12px;
                font-weight: 400;
                display: inline-flex;
                align-items: center; } }

        .buttons {
            position: relative;
            background: #fff;
            z-index: 10000; } } }

.appointment-block-help-tooltip-content {
    padding: 10px;
    .heading {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px; }

    .explanation {
        font-size: 12px;
        line-height: 17px; } }

.session-label-menu-item {
    padding: 0 !important;
    background: #f6f7f8;
    border-bottom: 1px solid #e6e6e6 !important;

    .appointment-block-menu-item-content {
        &.is-odd {
            background: #f6f7f8; }

        &:hover {
            background: #EBF4FF; } } }

.appointment-block-menu-item-content {
    padding: 15px 30px 15px 20px;
    background: #fff;
    border-bottom: 1px solid #e6e6e6;

    &.is-odd {
        background: #f6f7f8; }

    &:hover {
        background: #EBF4FF; }

    .main-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 500px;
        margin-bottom: 10px;

        .time-range {
            font-size: 14px;
            font-weight: 500;
            display: inline-flex;
            align-items: center;
            .MuiSvgIcon-root {
                color: #00B18F;
                margin-right: 3px; } }

        .vaccine-type {
            font-size: 13px;
            font-weight: 500;
            display: inline-flex;
            align-items: center; }

        .available-count {
            padding: 4px 8px;
            border-radius: 50px;
            font-weight: 500;
            color: #fff;
            font-size: 12px;
            background: #00B18F; } }

    .sub-line {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .appointment-length {
            display: inline-flex;
            align-items: center;


            .MuiSvgIcon-root {
                color: #888;
                margin-right: 3px; } }

        .calendar-info {
            display: inline-flex;
            align-items: center;


            .calendar-name {
                max-width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis; }

            .MuiSvgIcon-root {
                margin-right: 3px; } }

        .edit-button {
            padding: 3px 5px; } } }

.create-new-appt-block-button {
    .MuiButton-label {
        color: #1A84FF; } }

.appt-block-delete-overlay {
    background: rgba(0,0,0,0.0);
    position: absolute;

    .heading {
        height: 21px; }

    .explanation {
        margin-bottom: 10px; } }

