.appointment-status-icon {

    &.with-future-appts {
        .MuiSvgIcon-root {
            color: #01BB97; } } }


.appointment-status-icon-tooltip-title {
    .heading {
        width: 250px;
        height: 30px;
        margin: 5px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
        font-weight: 500;

        .count {
            display: inline-flex;
            width: 25px;
            height: 25px;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            background: #01BB97;
            color: #fff; } }

    .appointments {
        margin-bottom: 5px;

        &.past {
            .appt-type-and-time {
                background: rgba(255,255,255,0.65); }
            .sub-heading {
                font-size: 13px;
                font-weight: 500; } }

        .appt-type-and-time {
            background: rgba(255,255,255,0.85);
            font-size: 13px;
            color: #000;
            padding: 10px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            font-weight: 400;
            margin: 10px 0;

            .MuiSvgIcon-root {
                font-size: 24px;
                margin-right: 10px; }

            .when {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 10px; } } }

    .more-count {
        font-size: 11px;
        color: #eee;
        text-align: right;
        margin-bottom: 5px; } }
