.upload-selection-file-content {
    display: flex;

    .left {
        padding: 10px 20px; }

    .right {
        width: 460px;
        .heading {
            margin-bottom: 10px;

            .main-line {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 10px; }

            .sub-line {
                font-size: 13px; } }

        .file-drop-area {
            cursor: pointer;
            border:  2px dashed #d3d3d3;
            background: #ffffff;
            outline: none;

            &:hover {
                border: 2px dashed #1A84FF;
                background: #EAF4FF; } } } }
