.review-patient-cell {
    .non-edit-value {
        .empty-value {
            font-size: 12px;
            color: #777; } } }

.review-patient-cell-tooltip {
    .raw-area {
        padding: 2px 4px;
        margin: 5px 0;
        background: #fff;
        color: #000;
        font-size: 12px;
        min-height: 16px; } }

