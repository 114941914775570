.reminders-configuration {
    min-height: 390px;

    .MuiAccordion-root {
        background: #f7f8f9;
        margin-bottom: 10px; }

    .MuiAccordionSummary-root {
        font-size: 16px;
        font-weight: 500;
        color: #555; }

    .message-customization-text-field {
        background: #fff; } }
