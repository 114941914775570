
.appointment-block-row {
    padding: 15px 20px;
    height: 130px;
    background: #fff;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;



    &.is-odd {
        background: #f6f7f8; }

    &:hover {
        background: #EBF4FF; }

    &.disallow-selection {
        cursor: default;
        &:hover {
            background: inherit; } }

    .main-line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .time-range {
            width: 145px;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            white-space: nowrap;
            .MuiSvgIcon-root {
                color: #00B18F;
                margin-right: 3px; } }

        .vaccine-type {
            display: flex;
            align-items: center;
            font-weight: 14px;
            font-weight: 500;
            width: 170px; } }

    .explain-line {
        font-size: 12px;
        font-weight: 300;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: space-between;

        > div {
            display: flex;
            align-items: center; }

        .MuiSvgIcon-root {
            margin-right: 3px;
            color: #888;
            font-size: 20px; }

        .calendar-name {
            font-size: 12px;
            display: inline-flex;
            align-items: center;
            font-weight: 500;
            margin: 0 5px; } }

    .stats-line {
        display: flex;
        align-items: center;
        font-size: 12px;
        justify-content: space-between;

        .stat-value {
            padding: 3px 12px;
            border-radius: 10px;
            background: rgba(0,0,0,0.05);
            border: 1px solid rgba(0,0,0,0.05); } }

    .time-and-length {
        display: flex;
        flex-direction: column;
        height: 65px;
        justify-content: space-around;

        .time-range {
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            .MuiSvgIcon-root {
                color: #00B18F;
                margin-right: 3px; } }

        .appointment-length {
            display: flex;
            align-items: center;
            font-size: 12px; }


        .MuiSvgIcon-root {
            color: #888;
            margin-right: 3px; } }

    .vaccine-type-and-calendar {
        display: flex;
        flex-direction: column;
        height: 65px;
        justify-content: space-around;


        .vaccine-type {
            font-size: 13px;
            font-weight: 500;
            display: flex;
            align-items: center; }

        .calendar-info {
            display: flex;
            align-items: center;
            font-size: 12px;

            .calendar-name {
                max-width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis; }

            .MuiSvgIcon-root {
                margin-right: 3px; } } }

    .available-count {
        padding: 4px 8px;
        border-radius: 50px;
        font-weight: 500;
        color: #fff;
        font-size: 12px;
        background: #00B18F; } }




