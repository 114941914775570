.csv-column-summary {
    border: 1px solid #ddd;
    border-radius: 4px;
    min-width: 100px;
    max-width: 300px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 2px 2px 6px rgba(0,0,0,0);
    transition: box-shadow 0.3s;
    background: #eee;

    &:hover {
        box-shadow: 2px 2px 6px rgba(0,0,0,0.15); }

    .header {
        padding: 5px;
        font-weight: 500;
        background: #eee;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        white-space: nowrap;
        height: 55px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .column-label {
            display: flex;
            align-items: center; }

        .icon-wrapper {
            background: #fff59d;
            padding: 1px;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            border: 1px solid #ccc;
            box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
            margin-right: 5px;

            .MuiSvgIcon-root {
                font-size: 19px;
                color: #000; } }



        .mapped-label {
            background: #DCF3FA;
            border: 1px solid #B1E4F1;
            display: inline-flex;
            align-items: center;
            padding: 2px 6px;
            border-radius: 5px;
            .MuiSvgIcon-root {
                color: #00B18F;
                font-size: 13px;
                margin-right: 2px; }
            color: #000;
            font-size: 11px; } }

    .rows {
        background: #fff;
        .row {
            padding: 5px;
            border-top: 1px solid #ddd;
            font-size: 13px;
            color: #333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .empty {
                color: #888; } } }

    &.no-headers {
        .rows {
            .row:first-child {
                border-top: 0; } } }

    &.selected {
        border: 1px solid #1A84FF;
        .header {
            background: #52A1FC;
            color: #fff; }



        .rows {
            .row {
                border-top: 1px solid #1A84FF; } } } }

.derived-column-tooltip-text {
    .main-line {
        font-size: 13px;
        margin: 5px 0; }

    .sub-line-1, .sub-line-2 {
        margin-bottom: 5px; } }

