.date-selection-calendar {
    background: #fff;
    .navigation-shortcuts {
        display: flex;
        justify-content: space-around;

        .nav-button {
            text-transform: none;
            color: #666;

            &.selected {
                color: #1A84FF; } } }

    .heading-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;

        .focused-month-label {
            font-size: 14px;
            font-weight: 500;
            margin-left: 10px; }

        .arrows {
            .MuiIconButton-root {
                padding: 8px; }
            .MuiSvgIcon-root {
                font-size: 28px;
                color: #aaa;
                transition: color 0.3s ease-in;
                &:hover {
                    color: #000; } } } }

    .calendar-cells {
        //padding: 0 10px
        .calendar-row {
            display: flex;
            justify-content: space-between;
            padding: 0 10px;

            &.day-of-week-letters {
                pointer-events: none; }

            .has-appointment-background {
                position: absolute;
                width: 25px;
                height: 25px;
                background: #EAF4FF;
                border-radius: 3px; }

            .cell {
                font-size: 13px;
                width: 25px;
                height: 25px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: #888;
                text-decoration: line-through;
                position: relative;
                border-radius: 50%;
                transition: background 0.2s, color 0.2s;

                &.has-appointments {
                    font-weight: 500; }

                &.focusable {
                    cursor: pointer;
                    text-decoration: none;
                    &:hover {
                        background: #eee; }

                    &.current-month {
                        color: #000; }

                    &.today {
                        font-weight: 500;
                        background: #ACD2FF; }

                    &.focused-date {
                        background: #1A84FF;
                        color: #fff; } }

                &.header {
                    color: #555;
                    text-decoration: none; } }



            &.focused-week {
                background: #1A84FF;

                .cell {
                    color: #fff;

                    &:hover {
                        background: transparent; }

                    &.today {
                        background: #58A6FF; } } }

            &.highlighted-week {
                background: #EAF4FF; } } }

    &.is-week-selection {
        .calendar-cells {
            .calendar-row {
                cursor: pointer;
                &:hover {
                    background: #eee; }

                &.focused-week {
                    &:hover {
                        background: #1A84FF; } }

                .cell {
                    &:hover {
                        background: transparent; } } } } } }


