.filter-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .filters-area {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end; }

    .main-label {
        font-size: 12px;
        color: #666;
        white-space: nowrap; } }

.waitlist-name-and-count {
    min-width: 140px;
    display: flex;
    justify-content: space-between;

    .count {
        color: #fff;
        background: #00B18F;
        padding: 0 8px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        border-radius: 10px; } }

