.template-management-row {
    position: relative;
    padding: 10px;
    border-bottom: 1px solid #ddd;

    &.disabled {
        .image-box, .text {
            opacity: 0.5; } }

    .template-management-row-inner {
        display: flex;
        align-items: top; }

    &.unfocused {
        .template-management-row-inner {
            height: 125px;
            overflow: hidden; }

        .text {
            .bottom-gradient {
                display: block; }

            .message {
                cursor: pointer; } } }

    &.is-odd {
        background: #f8f8f8;
        .text {
            .bottom-gradient {
                background-image: linear-gradient(rgba(248,248,248, 0), rgba(248,248,248,1)); } } }

    .switch-wrapper {
        display: flex;
        height: 125px;
        align-items: center; }

    .image-box {
        border-radius: 5px;
        position: relative; }

    .updating-message {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 5px 10px;
        border-radius: 5px;
        background: rgba(0,0,0,0.9);
        color: #fff;
        display: flex;
        align-items: center; }


    .text {
        position: relative;
        margin-left: 20px;
        width: calc(100% - 275px);

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            height: 40px;

            .header {
                max-width: calc(100% - 90px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis; }

            .targeting-explanation {
                background: #00B18F;
                color: #fff;
                font-size: 11px;
                padding: 2px 7px;
                border-radius: 4px; }

            .actions {
                .MuiIconButton-root {
                    padding: 8px; }

                .edit-icon, .restore-icon {
                    color: #1A84FF; }

                .delete-icon {
                    color: #DF4851; } } }

        .subject {
            font-size: 12px;
            margin-bottom: 10px;
            white-space: nowrap;
            width: calc(100% - 20px);
            overflow: hidden;
            text-overflow: ellipsis; }

        .message {
            font-size: 12px; }

        .bottom-gradient {
            pointer-events: none;
            display: none;
            position: absolute;
            bottom: 0;
            height: 30px;
            width: 100%;
            opacity: 1;
            transition: opacity 0.3s;
            background-image: linear-gradient(rgba(255,255,255, 0), rgba(255,255,255,1)); } } }

.text-hover-popup {
    padding: 5px;
    font-size: 12px;
    pointer-events: none; }
