.appointments-config {
    margin: 100px 20px 20px 20px;
    border-radius: 4px;
    max-width: 900px;
    border: 1px solid #e6e6e6;
    padding: 10px;

    .service-heading {

        .section-title {
            font-size: 18px; } }

    .not-enabled-content {
        margin-top: 60px;
        text-align: center;
        color: #555; }


    .additional-explanation {
        padding: 10px;
        font-size: 13px; }

    .container-row {
        padding: 0 10px; }

    .code-block {
        background: #4c667d;
        color: #fff;
        padding: 10px;
        font-size: 12px;
        font-weight: 500;
        width: 100%; }

    .appointment-types-page {
        .appointment-types-content {
            margin-top: 0; } } }

