.clinical-scheduling-content {
    padding: 0 !important;

    .date-range-bar {
        position: relative;

        .main-bar {
            background: #444;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            padding: 0 20px; }

        .slide-down-bar {
            background: linear-gradient(180deg, #444444 0%, rgba(68, 68, 68, 0.9) 100%);
            height: 0px;
            overflow: hidden;
            transform-origin: top left;
            //display: none
            transition: height 0.2s ease-in-out;
            position: absolute;
            z-index: 1000;
            width: 100%;
            //position: relative

            .selection-instructions {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                transition: opacity 0.3s;
                transition-delay: 0.2s;
                opacity: 0;
                p {
                    color: #fff; } }


            &.open {
                //display: block
                height: 340px;

                .selection-instructions {
                    opacity: 1;

                    &.hidden {
                        opacity: 0.5; } } } }



        .label {
            font-size: 15px;
            font-weight: 500;
            width: 230px; }

        .date-range {
            .from-date-time, .to-date-time {
                .heading {
                    height: 30px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 500; }

                .MuiInputBase-root {
                    background: #fff;
                    border-radius: 4px; }

                .MuiInputBase-input {
                    width: 135px;
                    font-size: 13px; }

                .MuiOutlinedInput-input {
                    padding: 10px 0px; }

                .MuiOutlinedInput-adornedEnd {
                    padding-right: 10px; }

                .MuiOutlinedInput-adornedStart {
                    padding-left: 10px; }

                .MuiInputAdornment-positionEnd {
                    .MuiSvgIcon-root {
                        color: #555; } }

                .MuiSvgIcon-root {
                    color: #1A84FF; } }

            .from-date-time {
                pointer-events: none;
                .MuiInputBase-root {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px; } }

            .to-date-time {
                pointer-events: none;
                .MuiInputBase-root {
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px; } } }

        .available-slot-counts {
            width: 230px;
            display: flex;
            justify-content: center;
            .empty-message {
                font-size: 12px;
                text-align: center; }

            .counts {
                display: inline-flex;
                align-items: center;
                background: #D1F1EB;
                color: #000;
                height: 30px;
                padding: 0 10px;
                border-radius: 15px;

                .MuiSvgIcon-root, .MuiCircularProgress-root {
                    color: #00B18F;
                    margin-right: 5px; }

                .slots-count {
                    font-size: 13px;
                    font-weight: 500;
                    white-space: nowrap; }

                .days-count {
                    font-size: 13px;
                    color: #666;
                    margin-left: 10px;
                    margin-right: 5px;
                    white-space: nowrap; } } } }

    .calendars {
        display: flex;
        justify-content: center;




        .calendars-dropdown-content {
            display: flex;
            padding: 0px 10px;
            width: 520px;

            .from-calendar-and-time, .to-calendar-and-time {
                width: 250px;
                background: #fff;
                margin: 0 5px;
                padding: 0px;

                .heading {
                    font-size: 14px;
                    font-weight: 500;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    background: #eee;
                    padding: 0 10px; } }


            .time-select-area {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                .any-label {
                    font-size: 12px;
                    margin-right: 5px; } } } } }

