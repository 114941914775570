.patients-page {
    margin-top: 80px;
    font-family: Roboto, sans-serif;
    padding: 0 20px;

    .patients-page-inner {
        height: calc(100% - 20px);
        max-width: 1400px;
        margin: auto;
        padding: 10px 0;

        .table-and-sidebar {
            display: flex;
            height: calc(100% - 60px); } }

    .unselect-button {
        .MuiButton-label {
            color: #F5A8A8; } }

    .connect-button, .view-button {
        .MuiButton-label {
            color: #56CCF2; } }

    .loading-overlay {
        position: fixed;
        top: 200px;
        left: 50%; } }

