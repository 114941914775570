.workflow-scheduling-page {
    max-width: 1100px;
    margin: auto;
    border-radius: 6px;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.075);

    .workflow-content {
        border: 1px solid #ddd;
        border-top: 0;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .workflow-inner-content {
            padding: 20px; } } }

