.appointments-page {
    font-family: Roboto, sans-serif;
    display: flex;
    //max-width: 1100px
    margin-top: 80px;
    background: linear-gradient(105.79deg, #F7F8F9 10.38%, #FCFCFC 100%);

    .appointments-left-bar {
        display: flex;
        flex-direction: column;
        width: 250px;
        background: #fafafa;
        //border-left: 1px solid #ddd
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
        // offsetting the header's height
        // - this is required to have the calendar list take all the
        //   available sidebar real estate
        height: calc(100vh - 80px);

        .clinical-appointments-and-list-of-calendars {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: stretch;
            flex: 1 1 auto;
            overflow: auto;

            .clinical-appointments {
                height: 290px;
                flex: 0 1 auto; }

            .heading {
                font-size: 16px;
                font-weight: 500;
                height: 50px;
                display: flex;
                align-items: center;
                color: #aaa;
                padding: 10px 20px;
                flex: 0 1 auto; }

            .visible-calendars {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                flex: 1 1 auto;
                overflow: auto;

                .list-of-calendars {
                    flex: 1;
                    border-top: 1px solid #eeeeee;
                    overflow-x: hidden;
                    overflow-y: auto;
                    flex: 1 1 auto;

                    .calendar-slot {
                        display: flex;
                        height: 50px;
                        width: 249px;
                        align-items: center;
                        background: #ffffff;
                        border-bottom: 1px solid #eee;

                        .vacation-icon {
                            margin: 0 20px 0 5px; }

                        &.empty {
                            justify-content: center;
                            font-size: 11px;
                            color: #888; }

                        &.loading {
                            justify-content: center; }

                        .text {
                            .name {
                                font-size: 13px;
                                font-weight: 500;
                                width: 160px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis; }

                            .sub-line {
                                .MuiButtonBase-root {
                                    padding: 0; }
                                .MuiButton-label {
                                    font-size: 12px;
                                    text-transform: none;
                                    color: #1A84FF;
                                    font-weight: 400; } } } } }

                .buttons {
                    height: 30px;
                    margin: 10px 5px;
                    text-align: right;
                    flex: 0 1 auto;

                    .MuiButtonBase-root {
                        color: #1A84FF; } } } } }

    .appointments-main-content {
        position: relative;
        max-width: 900px;
        margin: 0 auto;

        .main-loading-indicator {
            position: absolute;
            left: 50%;
            top: 300px;
            transform: translateX(-50%); }

        .no-calendars-message {
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #888888;
            text-align: center;
            margin-top: 160px;
            .MuiButtonBase-root {
                margin: 0 5px; }

            .MuiButton-label {
                text-transform: none;
                color: #1A84FF;
                font-size: 16px; }
            .MuiButton-text {
                padding: 0; } }


        .heading {
            padding: 0 20px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-size: 16px;
                font-weight: 500; }

            .add-appt-button {
                .MuiSvgIcon-root {
                    margin-right: 5px; } } }

        .appointments {
            padding: 0 20px;
            margin: auto; } } }


@media print {
    #root > header, #root footer, .mobile-custom-intercom {
        display: none; }

    .appointments-page {
        height: auto !important;
        margin-top: 0;
        .appointments-left-bar {
            display: none; }

        .add-appt-button {
            display: none !important; } } }


