.who-to-contact-content {
    padding: 20px 30px;

    .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .title {
            font-size: 17px;
            font-weight: 500; }

        .manual-button {
            text-transform: none;
            font-weight: 400;
            font-size: 14px;
            color: #1A84FF; } }

    .sub-heading {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 40px; }

    .questions-and-buttons {
        padding: 0 40px; }

    .patient-count-question {
        .question-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .question {
                font-size: 17px;
                font-weight: 500; }

            .recommendation-summary {
                font-size: 12px;
                font-weight: 400;
                display: inline-flex;
                align-items: center;

                .patient-count {
                    font-weight: 500; }

                .scheduling-assumption-text-field {
                    width: 90px;

                    .MuiOutlinedInput-input {
                        padding: 10px 0px 10px 14px; } }

                .explain-button {
                    text-transform: none;
                    color: #1A84FF;
                    font-weight: 400; } } }

        .question-sub-heading {
            font-size: 13px;
            font-weight: 400;
            margin: 10px 0 20px 0; }

        .patient-count-text-field {
            width: 300px; } }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;

        .change-order-button {
            text-transform: none;
            color: #1A84FF;
            font-weight: 400; }

        .back-button {
            margin-right: 10px; }

        .next-button {
            font-size: 15px; } } }

.recommendation-explanation-dialog {
    font-family: Roboto, sans-serif;

    .heading {
        font-size: 15px;
        font-weight: 500; }

    .known-facts {

        .heading {
            margin-bottom: 10px; }

        .facts {
            display: flex;
            align-items: center; }

        .fact {
            background: #f6f7f8;
            font-weight: 500;
            border: 1px solid #e6e6e6;
            height: 40px;
            display: inline-flex;
            padding: 0 20px;
            align-items: center;
            border-radius: 6px;
            margin: 0 10px; } }

    .assumptions {
        margin-top: 20px;

        .heading {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 10px; }

        .scheduling-assumption {
            background: #f6f7f8;
            font-weight: 500;
            border: 1px solid #e6e6e6;
            margin: 0 10px;
            border-radius: 6px;

            .heading {
                font-size: 13px;
                padding: 10px 20px; }

            .change-it-text {
                font-size: 12px;
                font-weight: 400;
                color: #666; }

            .slider-and-text-field {
                display: flex;
                align-items: center;
                padding: 0 20px;
                margin-bottom: 10px;

                .scheduling-assumption-slider {
                    margin-right: 20px;

                    .MuiSlider-rail, .MuiSlider-track, .MuiSlider-mark {
                        height: 5px; }

                    .MuiSlider-rail, .MuiSlider-track {
                        border-radius: 2px; }

                    .MuiSlider-thumb {
                        width: 16px;
                        height: 16px;
                        margin-top: -6px;
                        margin-left: -8px; } }

                .scheduling-assumption-text-field {
                    background: #fff;
                    width: 110px;

                    .MuiOutlinedInput-input {
                        padding: 10px 0px 10px 14px; } } } } }

    .recommendation {
        margin-top: 20px;
        margin-bottom: 10px;

        .heading {
            margin-bottom: 10px; }

        .count-and-explanation {
            display: flex;
            align-items: center; }

        .recommendation-count {
            background: #f6f7f8;
            font-weight: 500;
            border: 1px solid #e6e6e6;
            margin: 0 10px;
            border-radius: 6px;
            padding: 10px 20px;

            .count {
                display: flex;
                align-items: center;
                font-size: 15px;

                .MuiSvgIcon-root {
                    color: #00B18F;
                    font-size: 32px;
                    margin-right: 10px; } } }

        .explanation {
            font-size: 12px;
            font-weight: 400;
            max-width: 500px; } } }

.ordering-details-dialog {
    font-family: Roboto, sans-serif;

    .heading {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 20px; }

    .ordering-option {
        margin: 10px 0;
        border: 1px solid #e6e6e6;
        padding: 10px 20px;
        border-radius: 6px;
        box-shadow: 2px 2px 5px rgba(0,0,0,0);
        min-height: 120px;
        cursor: pointer;

        &:hover {
            box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
            transition: all 0.2s; }

        &.selected {
            background: #EBF4FF;
            color: #1A84FF;
            border-color: #82BCFF;
            box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
            cursor: default; }

        .label {
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 10px; }

        .explanation {
            font-size: 12px;
            font-weight: 400; } } }
