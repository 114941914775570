.templates-management-page {
    min-height: 500px;
    .templates-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 10px;

        .search-and-count {
            display: inline-flex;
            align-items: center;

            .search-text-field {
                margin-left: 5px;
                .MuiInputBase-input {
                    padding: 12px 14px 12px 0; } }

            .count {
                margin-left: 20px;
                font-size: 12px;
                color: #555; } } }

    .templates-list {
        outline: none;
        border: 1px solid #eee;

        .no-rows {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 85px;
            width: 100%; } }

    .create-action-line {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end; } }
