.coronavirus-settings {
    position: relative;

    .superseded-by-scheduling-message {
        display: flex;
        align-items: center;
        background: #FFF7AC;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.075);

        padding: 10px 20px;
        margin-bottom: 20px;

        .MuiSvgIcon-root {
            font-size: 32px;
            margin-right: 10px; }

        .text {
            .main-line {
                font-size: 15px; }
            .sub-line {
                font-size: 13px; } } }

    .temporarily-disabled-message {
        display: flex;
        align-items: center;
        background: #FFF6A0;
        padding: 10px 20px;
        font-size: 13px;
        margin-bottom: 30px;

        .MuiSvgIcon-root {
            font-size: 36px;
            margin-right: 10px;
            color: #555; }

        .MuiButton-root {
            text-transform: none;
            padding: 0; } }

    .covid-website-options-line {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .text {
            .MuiTypography-body1 {
                font-size: 16px;
                font-weight: 400; }

            .MuiTypography-body2 {
                font-size: 0.875rem;
                color: rgb(93,93,93); } }

        .covid-website-option-select {
            min-width: 200px;
            height: 40px;
            margin-left: 20px; } }

    .updating-indicator {
        position: absolute;
        display: flex;
        align-items: center;
        right: 0;
        top: -60px;
        font-size: 13px;
        padding: 10px 20px;
        background: #EAF4FF;
        border-radius: 6px;
        box-shadow: 2px 2px 4px rgba(0,0,0,0.1); }

    .code-block {
        width: 100%; } }
