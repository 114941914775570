.vaccine-dose-info-content {
    max-width: 700px;
    margin: 30px auto;

    .question {
        margin-bottom: 40px;

        .heading {
            font-size: 17px;
            font-weight: 500;
            margin-bottom: 10px; }

        .sub-heading {
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 20px; }

        .vaccine-type-select, .dose-count-text-field {
            width: 250px; } }

    .buttons {
        display: flex;
        justify-content: flex-end;

        .back-button {
            margin-right: 10px; }

        .next-button {
            font-size: 15px; } } }

