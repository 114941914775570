.create-or-edit-appointment-calendar-dialog {
    font-family: Roboto, sans-serif;
    position: relative;

    .saving-button-label {
        display: inline-flex;
        align-items: center; }

    .MuiDialogTitle-root {
        h2 {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                display: inline-flex;
                align-items: center;

                .MuiSvgIcon-root {
                    color: #555;
                    margin-right: 5px;
                    font-size: 28px; } } } }

    .MuiDialogContent-root {
        width: 870px; }

    .explanation {
        font-size: 13px;
        margin-bottom: 20px; }

    .name-and-tz {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .name-text-field {
            width: 360px;
            .MuiOutlinedInput-input {
                padding: 14px; }

            .MuiInputLabel-outlined {
                transform: translate(14px, 16px) scale(1);
                &.Mui-focused, &.MuiFormLabel-filled {
                    transform: translate(14px, -6px) scale(0.75); } } }

        .label-and-tz-select {
            display: flex;
            align-items: center;

            .label {
                font-size: 13px;
                color: #888;
                margin-right: 10px; }

            .MuiInputBase-root {
                padding: 5px; }

            .timezone-autocomplete {
                font-size: 13px; } } }

    .title-and-tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .tab-title {
            font-size: 16px;
            font-weight: 500; }

        .tabs {
            .tab-button {
                font-size: 13px;
                text-transform: none;
                font-weight: 400;
                margin-left: 10px;
                padding: 6px 12px;
                transition: all 0.3s ease-in;
                border: 1px solid transparent;

                &.selected {
                    background: #EBF4FF;
                    border: 1px solid #ACD2FF;
                    color: #1A84FF;
                    pointer-events: none; } } } } }

.confirm-delete-overlay {
    background: rgba(0,0,0,0.1);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1.5px);
    z-index: 1;
    overflow: hidden;
    animation: blur-it 0.3s;

    @keyframes blur-it {
        from {
            backdrop-filter: blur(0px); }
        to {
            backdrop-filter: blur(1.5px); } }

    .confirm-delete {
        position: absolute;
        top: 0px;
        background: #DF4851;
        color: #fff;
        padding: 20px;
        box-shadow: 5px 5px 20px rgba(0,0,0,0.2);
        left: 50%;
        transform: translateX(-50%);
        animation: slide-down 0.3s ease-in;

        .heading {
            font-size: 15px;
            font-weight: 500; }

        .explanation {
            font-size: 13px;
            margin-top: 10px; }

        .delete-buttons {
            display: flex;
            justify-content: flex-end;

            .MuiButton-root {
                color: #fff;
                margin-left: 10px; }

            .MuiButton-contained {
                color: #DF4851; } } } }

@keyframes slide-down {
    from {
        transform: translate(-50%, -100%); }
    to {
        transform: translateY(-50%, 0%); } }
