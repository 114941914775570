.vacation-configuration {
    min-height: 390px;
    position: relative;

    .empty-message {
        font-size: 16px;
        color: #888888;
        padding-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        .MuiButton-root {
            text-transform: none;
            color: #1A84FF;
            font-size: 16px;
            padding: 0 3px; } }

    .vacation-blocks {
        border-top: 1px solid #e6e6e6;
        max-height: 401px;
        overflow-y: scroll;

        .vacation-block {
            border: 1px solid #e6e6e6;
            border-top: 0;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fff;
            height: 100px;

            &.odd {
                background: #fafafa; }

            &.invalid {
                background: #fadcdc; }

            .date-line {
                display: flex;
                align-items: center; }

            .main-label {
                display: inline-block;
                text-align: right;
                width: 35px;
                font-size: 12px;
                color: #555; }

            .date-picker {
                margin: 0 10px;
                cursor: pointer;
                background: #fff;

                .calendar-icon {
                    color: #888;
                    font-size: 18px; }

                .dropdown-icon {
                    color: #888; }

                .MuiInputBase-root {
                    padding: 0 6px; }

                .MuiInputBase-input {
                    padding: 9px 0;
                    width: 90px; } }

            .all-day {
                cursor: pointer; }

            .capacity-select {
                background: #fff;
                font-size: 13px;
                .MuiOutlinedInput-input {
                    padding: 10px 32px 10px 14px; } }

            .delete-button {
                .MuiSvgIcon-root {
                    color: #ED7E7E; } } } }


    .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
        .add-button {
            color: #1A84FF;
            .MuiSvgIcon-root {
                margin-right: 3px; } } } }


