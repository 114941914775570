.connect-card-grid {
    width: 100%;

    &.loading {
        .progress-wrapper {
            position: fixed;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%); } }

    .filter-line {
        position: relative;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .left-content {
            display: inline-flex;
            align-items: center; }

        .search-text-field {
            margin-left: 5px;
            .MuiInputBase-input {
                padding: 12px 14px 12px 0; } }

        .clear-icon {
            cursor: pointer; }

        .counts {
            margin-left: 10px;
            font-size: 13px;
            color: #888; }

        .keyboard-directions {
            position: absolute;
            left: 5px;
            top: calc(100% + 5px);
            font-size: 12px;
            color: #555;
            display: inline-flex;
            justify-content: space-between;
            width: 400px;
            white-space: nowrap;

            .card-instructions {
                width: 290px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis; }

            strong {
                color: #000; } } }


    .grid-contents {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: center;
        overflow-y: scroll;

        .empty-grid-info {
            display: flex;
            margin: 20px auto;
            .grid-icon {
                font-size: 90px;
                color: #ccc;
                margin-right: 10px;
                transform: rotate(90deg); }

            .manage-info {
                margin: 10px 0;
                color: #555;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .main-line {
                    font-size: 20px;
                    margin-bottom: 5px; }

                .sub-line {
                    font-size: 13px;
                    .manage-link {
                        text-decoration: none;
                        color: #1A84FF; } } } }

        .grid-card-holder {
            margin: 5px;
            background: #fff;
            //overflow: hidden

            &.is-over {
                opacity: 0.2; }

            &.no-drop {
                background: red; } }

        .grid-card {
            &.is-dragging {
                opacity: 0.5; }

            &.filtered-out {
                opacity: 0.25;
                pointer-events: none; } } } }
