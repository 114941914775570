.weekly-schedule-configuration {
    .title {
        font-size: 16px;
        font-weight: 500; }

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px; }



    .week-days-configuration {
        border: 1px solid #e6e6e6;
        border-bottom: 0;
        overflow-y: scroll;

        .week-row {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e6e6e6;
            min-height: 55px;

            .day {
                font-size: 15px;
                font-weight: 500;
                width: 90px; }

            .row-content {
                display: flex;
                align-items: center;

                &.empty {
                    font-size: 12px;
                    color: #555; }

                .add-block-button {
                    padding: 10px;
                    color: #1A84FF;
                    height: 34px;
                    width: 34px;
                    margin-right: 5px; }

                .hour-blocks {
                    margin-top: 10px;
                    .hour-block {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;

                        .label {
                            font-size: 12px;
                            color: #888888;
                            margin: 0 10px; }

                        .capacity-select {
                            margin-left: 20px;
                            margin-right: 10px;
                            width: 180px;
                            .MuiSelect-root {
                                padding: 9px 32px 9px 10px;
                                border-radius: 6px;
                                border-color: #ddd; } }

                        .empty-icon-spacer {
                            width: 45px; }

                        .delete-button {
                            color: #ED7E7E; } } } } } } }


