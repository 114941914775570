.bulk-patient-upload-dialog {
    padding: 20px; }

.bulk-patient-finished-snackbar {
    .MuiSnackbarContent-root {
        background: #27AE60; }
    .bulk-patient-finished-snackbar-content {
        display: flex;
        align-items: center;

        .left {
            margin-right: 10px;
            .MuiSvgIcon-root {
                font-size: 40px; } }

        .right {
            display: flex;
            flex-direction: column;

            .main-line {
                font-weight: 500;
                font-size: 13px; }

            .sub-line {
                font-size: 11px; } } } }
