.appointment-type-content {
    padding: 0 20px;
    position: relative;
    .heading {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-size: 16px;
            font-weight: 500; }

        .manual-create {
            color: #888;

            .one-off-button {
                text-transform: none;
                color: #1A84FF;
                padding: 3px 3px;
                font-size: 13p; } } }

    .sub-heading {
        font-size: 13px;
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .appt-type-name-filter {
            font-size: 13px;
            .MuiOutlinedInput-input {
                padding: 10px;
                width: 340px; } }

        .manage-appt-types-link-button {
            text-transform: none;
            font-weight: 400; } }

    .ReactVirtualized__Grid {
        outline: none; }

    .focus-instructions {
        display: flex;
        justify-content: space-between;
        position: absolute;
        font-size: 11px;
        font-weight: 300;
        margin-top: -24px;
        color: #444;
        width: 360px;

        .enter-instructions {
            white-space: nowrap;
            width: 235px;
            overflow: hidden;
            text-overflow: ellipsis; }


        .nav-instructions {
            display: flex; } }

    .appt-types-loading {
        position: absolute;
        left: calc(50% - 20px);
        top: 250px; }

    .no-matching-appt-types-message {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #888;
        margin-top: 80px;

        .MuiSvgIcon-root {
            font-size: 64px;
            margin-right: 10px;
            color: #aaa; }


        .text {
            .main-line {
                font-size: 18px; }
            .sub-line {
                font-size: 13px;

                .manage-shortcut-button {
                    margin: 0;
                    padding: 0;
                    text-transform: none; } } } }

    .advertisement {
        margin-top: 20px;

        .heading {
            height: 40px;

            .title {
                font-size: 14px; } }

        .coming-soon-advertisement {
            width: 550px;
            height: 138px;
            //box-shadow: 2px 2px 8px rgba(0,0,0,0.075)
            box-radius: 5px; } }

    .appointment-blocks-content {
        position: absolute;
        top: 0px;
        width: 550px;
        height: 670px;
        background: #fff;

        .appointment-type-and-back-button {
            margin-top: 20px;

            .back-button-wrapper {
                position: absolute;
                margin-left: -17.5px;
                top: 47.5px;

                .go-back-button {
                    border: 1px solid #e6e6e6;
                    padding: 6px;
                    margin-right: 10px; } }

            .appointment-type-row {
                pointer-events: none; } }

        .appointment-block-selector {

            &.from-existing {
                margin-top: 40px; }

            &.create-or-edit {
                position: absolute;
                left: -300px;
                width: 870px;
                height: 670px;
                top: -20px;
                background: #fff;
                margin-top: 20px; } } } }


.appointment-type-row {
    height: 92px;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    display: flex;
    transition: all 0.3s ease-in;
    background: #fff;
    cursor: pointer;
    margin-bottom: 10px;
    //position: relative

    &.selected {
        border: 1px solid #82BCFF;
        background: #EBF4FF; }

    &.focused, &:hover {
        box-shadow: 2px 2px 6px rgba(0,0,0,0.1);
        background: #EAF4FF;
        border: 1px solid #C1DDFF; }

    .image-box {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-right: 1px solid #e6e6e6; }

    .text {
        margin: 15px 20px;
        width: calc(100% - 135px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-size: 16px;
            font-weight: 500; }

        .details {
            display: flex;
            font-size: 12px;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .length, .calendar, .appt-blocks-available {
                display: inline-flex;
                align-items: center;

                .MuiSvgIcon-root {
                    margin-right: 3px;
                    color: #888; } }

            .appt-blocks-available {
                .MuiSvgIcon-root {
                    color: #00B18F; } }

            .calendar-select {
                min-width: 250px;

                .MuiSelect-root {
                    background: #fff;
                    font-size: 13px; }

                .MuiOutlinedInput-input {
                    padding: 8px 14px; } } } } }

