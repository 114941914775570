.phone-type-lookup-pane {
    display: flex;
    min-height: 250px;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    min-width: 800px;


    .phone-type-lookup-pane-inner {
        display: flex;
        max-width: 800px;
        //background: #f8f8f8
        border: 1px solid #ddd;
        height: 140px;
        border-radius: 5px;
        padding: 20px;

        .lookup-icon {
            font-size: 90px;
            margin-right: 20px; }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .main-text-line {
                font-size: 16px; }

            .subtitle-line {
                font-size: 13px;
                color: #333; } } } }


