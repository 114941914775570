.filtering-dropdown {

    .MuiButton-root:hover {
        background-color: rgba(0,0,0,0.02); }

    .MuiButton-outlined {
        border: 1px solid #ccc;
        height: 35px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 15px;
        margin: 5px 0 5px 5px;
        padding: 5px 10px 5px 15px; }

    .dropdown-icon {
        color: #888;
        transition: color 0.3s; }



    .label {
        color: #333;
        text-transform: none;
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        transition: color 0.3s; }

    &:hover {
        border-color: #555;
        .dropdown-icon {
            color: #000; }
        .label {
            color: #000; } }

    &.active {
        .MuiButton-outlined {
            background: #D6E9FE;
            border-color: #C1DDFF;
            box-shadow: 1px 1px 2px rgba(0,0,0,0.05); }

        .label {
            color: #1A84FF; }

        .close-icon {
            color: #58A6FF;
            margin-left: 5px;
            font-size: 20px;
            transition: color 0.3s;
            &:hover {
                color: #DF4851; } }

        .add-icon {
            color: #58A6FF;
            margin-right: 5px;
            margin-left: -5px;
            font-size: 20px;
            transition: color 0.3s;
            &:hover {
                color: #1A84FF; } } } }

.filtering-dropdown-popover {
    padding: 10px 10px;
    .popover-heading {
        font-size: 16px;
        font-weight: 500;
        height: 35px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {
            display: flex;
            height: 33px;
            align-items: center; } } }
