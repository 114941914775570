.empty-patients-results-pane {
    color: #555;
    text-align: center;
    padding: 100px 0;
    display: flex;
    justify-content: center;

    .graphic {
        margin-right: 20px;
        .graphic-inner {
            position: relative;

            .large-icon {
                font-size: 96px;
 }                //color: #aaa
            .small-icon {
                position: absolute;
                font-size: 36px;
                left: 21%;
                top: 20%;
 } } }                //transform: translate(-50%, -50%)

    .text {
        max-width: 600px;
        text-align: left; }
    .main-line {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px; }
    .explanation-line {
        margin-bottom: 10px; } }

