.edit-template-dialog {
    font-family: Roboto, sans-serif;

    .MuiDialogTitle-root h2 {
        display: flex;
        align-items: center;

        .title-text {
            font-weight: 400; }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 80px; }
        .image-box {
            border-radius: 5px;
            margin-right: 20px; }

        .clinical-note {
            font-size: 12px;
            font-weight: 400;
            width: 530px;
            color: #555; } }

    .inputs-and-preview {
        display: flex;

        .inputs {
            width: 400px;

            .accordion-title {
                display: inline-flex;
                align-items: center;
                .MuiSvgIcon-root {
                    margin-right: 10px; } } }

        .message-preview {
            margin-left: 20px;
            width: 300px;

            .phone-heading {
                max-width: 280px;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                color: #aaa;
                margin: 10px 0; } } } }
