.choose-patients-page {
    max-width: 1440px;
    margin: auto;
    .template-sidebar-header-component {
        display: flex;
        align-items: center;
        .image-box {
            border-radius: 5px;
            margin-right: 5px; }

        .text {
            display: inline-flex;
            flex-direction: column;
            justify-content: space-around;
            height: 50px;

            .title {
                font-size: 16px;
                font-weight: 500;
                white-space: nowrap;
                width: 250px;
                overflow: hidden;
                text-overflow: ellipsis; }
            .count {
                font-size: 13px;
                font-weight: 400; } } }

    .choose-patients-title-line {
        margin: auto;
        padding: 0 10px;
        display: flex;
        align-items: center;

        margin-bottom: 10px;

        .button-and-title {
            display: inline-flex;
            align-items: center; }

        .mini-card {
            display: inline-flex;
            align-items: center;
            //border: 1px solid #ddd
            border-radius: 5px;
            margin-right: 40px;
            margin-left: 40px;
            .image-box {
                border-radius: 5px;
                margin-right: 10px; }
            .title {
                font-size: 20px;
                font-weight: 500;
                white-space: nowrap;
                margin-right: 10px; } }

        .subtitle {
            font-size: 14px; } }

    .choose-patients-content {
        padding: 0px 20px;
        margin: auto;

        .table-and-sidebar {
            display: flex;
            height: calc(100% - 60px); } } }
