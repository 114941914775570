.homepage-container {
    margin-bottom: 16px;
}
.home-page__left_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .activity-title {
        font-size: 24px;
        padding: 17px 0px;
    }
}

.inbox-message-widget {
    height: 160px;
    border-radius: 12px;
    border: solid 1px #dfdfe6;
    margin-bottom: 16px;
    background-color: var(--colors-9-white);
}

.inbox-message-widget__title {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #292929;
    width: 100%;

    .avatar {
        margin-top: 14px;
        margin-left: 14px;
        color: #ffffff;
    }

    .text {
        margin-left: 22px;
        margin-top: 18px;
    }

    .forward-icon {
        margin-left: auto;
    }
}

.inbox-message-widget__body {
    margin-left: 68px;
    margin-right: 50px;
    height: 35px;
    display: flex;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #292929;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inbox-message-widget__input {
    margin-left: 68px;
    margin-top: 14px;
    margin-bottom: 22px;
    margin-right: 40px;
    display: flex;
    justify-content: space-between;

    .reply-btn-wrapper {
        position: relative;
    }

    .circular-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -12px;
        margin-left: -12px;
    }
}

.banner-widget {
    width: 684px;
    height: 287px;
    border-radius: 12px;
    box-shadow: 0 2px 9px 2px rgba(112, 83, 238, 0.46);
    background-color: #7053ee;
    margin-top: 100px;
}

.banner-widget__title {
    width: 403px;
    height: 30px;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 32px;
    margin-left: 32px;
}

.banner-widget__body {
    width: 403px;
    height: 80px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 32px;
    margin-left: 32px;
}

.banner-widget__mobile-background {
    width: 161px;
    height: 334px;
    border-radius: 21px;
    background-color: #7053ee;
    position: absolute;
    margin-top: -280px;
    margin-left: 480px;
    z-index: 1;
}

.banner-widget__mobile-foreground {
    position: absolute;
    width: 145px;
    height: 301px;
    border-radius: 19px;
    background-color: #ffffff;
    z-index: 2;
    margin-top: -260px;
    margin-left: 488px;
}

.banner-widget__mobile-oval-large {
    width: 78px;
    height: 78px;
    background-color: #f7b522;
    border-radius: 50%;
    position: absolute;
    z-index: 3;
    margin-top: -55px;
    margin-left: 515px;
}

.banner-widget__mobile-oval-medium {
    width: 30px;
    height: 30px;
    background-color: #f7b522;
    border-radius: 50%;
    position: absolute;
    z-index: 3;
    margin-top: -85px;
    margin-left: 565px;
}

.banner-widget__mobile-oval-small {
    width: 15px;
    height: 15px;
    background-color: #f7b522;
    border-radius: 50%;
    position: absolute;
    z-index: 3;
    margin-top: -50px;
    margin-left: 595px;
}

.banner-widget__button {
    width: 179px;
    height: 36px;
    border-radius: 8px;
    background-color: #ffffff;
    margin-top: 32px;
    margin-left: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-widget__button-text {
    width: 101px;
    height: 20px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7053ee;
    margin-top: 32px;
    margin-left: 32px;
}

.vm-widget-container {
    border: 1px solid #dfdfe6;
    border-radius: 8px;
    min-width: 448px;
    min-height: 100px;
    flex-grow: 1;
    margin-top: 16px;

    .badge {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #7a85ff;
    }
}

.vm-widget__body {
    margin-left: 68px;
    margin-right: 50px;
    min-height: 20px;
    display: flex;
    font-size: 12px;
    color: #292929;
}

.key-stats-container {
    width: 685px;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;

    .widget {
        width: 212px;
        border-radius: 12px;
        min-height: 249px;

        .title {
            font-size: 16px;
            font-weight: 600;
            color: #292929;
        }
    }
}

.checklist-widget-container {
    border: 1px solid #dfdfe6;
    border-radius: 8px;
    min-width: 448px;
    padding: 16px;
    color: #292929;

    .title {
        font-size: 16px;
        font-weight: 600;
    }

    .list-items-container {
        padding: 16px;
        :last-child {
            border-left-color: transparent;
        }
    }

    .list-item-container {
        padding-bottom: 16px;
        border-left: 3px solid #dfdfe6;
        border-left-style: dashed;

        .radio-btn {
            left: -12px;
            background: #fff;
            padding: 0px;
        }

        .action {
            font-size: 16px;
        }
    }
}

.divider-container {
    padding-top: 32px;
    padding-bottom: 32px;
    width: 50px;
}
