.patient-name {
    .name {
        margin-left: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: Roboto, sans-serif;
        font-size: 13px; }

    &.loading {
        display: flex;
        align-items: center;

        .avatar-silhouette {
            width: 30px;
            height: 30px;
            background: #ddd;
            border-radius: 50%; }

        .name-silhouette {
            width: 110px;
            height: 15px;
            background: #dddddd;
            margin-left: 10px;
            border-radius: 4px; } } }
