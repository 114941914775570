.patients-table-wrapper {
    max-width: 1000px;
    height: 100%;
    transition: width 0.3s;

    &.loading {
        .patients-table {
            opacity: 0.3;
            pointer-events: none; }

        .loading-indicator {
            position: absolute;
            top: 50%;
            left: 50%; } }

    .patients-table {
        position: relative;

        .initial-loading-message {
            position: absolute;
            left: 50%;
            top: 100px;
            transform: translateX(-50%); }

        .ReactVirtualized__Grid {
            outline: none;
            border: 1px solid #ddd; }

        .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid #ddd;
            box-shadow: 0px 1px 1px rgba(0,0,0,0.05);
            background: #fff !important; }

        .wrapper-row-box {
            &.unselectable {
                .ReactVirtualized__Table__row {
                    cursor: not-allowed;
                    &:hover {
                        background: #F7D4D6 !important; } } }

            .ReactVirtualized__Table__row {
                &:hover {
                    background: #D1EEFF !important; } } }



        .ReactVirtualized__Table__row {
            border-bottom: 1px solid #e6e6e6;
            height: 40px;
            outline: none;
            cursor: pointer;
            box-sizing: border-box;
            font-size: 13px;

            .patient-checkbox {
                color: #888;
                cursor: pointer;
                &.checked {
                    color: #007E66; }
                &.disabled {
                    cursor: not-allowed; } }

            .birthdate-cell {
                width: 105px;
                display: flex;
                justify-content: space-between;

                .birthdate-loading, .age-loading {
                    display: inline-block;
                    background: #dddddd;
                    height: 15px;
                    width: 70px;
                    border-radius: 4px; }

                .age-loading {
                    width: 15px;
                    border-radius: 4px; } }

            .phone-loading {
                display: inline-block;
                background: #dddddd;
                height: 15px;
                width: 85px;
                border-radius: 4px; } }


        .ReactVirtualized__Table__rowColumn {
            display: flex;
            align-items: center; }

        .ReactVirtualized__Table__headerColumn {
            outline: none;
            display: flex;
            align-items: center;

            .name-header {
                display: flex;
                align-items: center;
                .sort-icon {
                    font-size: 14px; } } }

        .fetch-error {
            display: flex;
            color: #555;
            width: 600px;
            margin: 80px auto;

            .graphic {
                margin-right: 20px;
                .alert-icon {
                    color: #DF4851;
                    font-size: 90px; } }

            .main-line {
                display: flex;
                font-size: 20px;
                margin-bottom: 10px;
                font-weight: 500; } } } }

.unselectable-number-tooltip-title {
    display: flex;
    padding: 10px;
    align-items: center;

    .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #312f2f;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        margin-right: 10px;
        padding: 0 10px; }
    .warning-icon {
        color: #ffeb3b;
        font-size: 30px; }

    .main-text {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 10px; } }
