.review-bulk-message-pane {

    .unmessageable-snackbar {
        max-width: 900px;
        .MuiPaper-root {
            background: #fff59d;
            width: 600px; }

        .MuiSnackbarContent-message {
            padding: 0; } }
    .unmessageable-patients-banner {
        color: #333;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        max-width: 700px;

        .phone-lookup-icon {
            font-size: 36px;
            margin-right: 10px; }

        .text {
            .main-line {
                font-size: 14px;
                font-weight: 500; }
            .sub-line {
                font-size: 11px; } } }


    .review-bulk-message-pane-content {
        display: flex;
        margin-bottom: 10px;

        .selected-template-pane {
            max-width: 660px;
            min-width: 450px;
            min-height: 300px;
            border-right: 1px solid #ddd;

            .subject {
                display: inline-flex;
                margin-bottom: 10px;
                max-width: calc(100% - 10px);
                .image-box {
                    width: 150px;
                    height: 100px;
                    border-radius: 10px;
                    margin-right: 10px; }

                .title-text {
                    width: calc(100% - 170px);
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: space-around;

                    .title {
                        font-size: 20px;
                        white-space: nowrap;
                        font-weight: 500;
                        overflow: hidden;
                        text-overflow: ellipsis; }

                    .how-it-works {
                        font-size: 12px; } } }

            .subject-text {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 10px;
                max-width: calc(100% - 20px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .label {
                    font-weight: 400;
                    font-size: 12px; } }


            .message-text {
                font-size: 12px;
                background: #f7f7f7;
                padding: 10px;
                border-radius: 5px;
                margin-right: 20px;
                box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
                max-width: 500px;

                .inline-yes-no-question-prompt {
                    margin-top: 10px; }

                .messaging-form-background-wrapper {
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,0.5);
                    top: 0;
                    left: 0;
                    z-index: 9999;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    pointer-events: none;

                    .phone-wrapper {
                        width: 360px;
                        height: 540px;
                        background: #444;
                        border-radius: 20px;

                        .explanation {
                            font-size: 13px;
                            text-align: center;
                            margin: 0 10px;
                            padding: 5px 10px;
                            transform: translateY(-35px);
                            background: rgba(255,255,255, 1);
                            border-radius: 6px; } } }


                .messaging-form.fullscreen {
                    font-family: Roboto, sans-serif;
                    max-height: 500px;
                    max-width: 320px;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    .messaging-form-inner {
                        height: 505px; } } } }



        .patients-area {
            width: 300px;
            margin-left: 20px;

            .patients-title {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 10px;
                height: 40px;
                display: flex;
                align-items: center; } } } }

