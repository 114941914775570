.patient-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .MuiIconButton-root {
        padding: 8px; }

    .patient-actions-disabled {
        cursor: default; } }
