.appointment-availability-row {
    height: 160px;
    border-radius: 12px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    overflow: hidden;

    &.active {
        .heading-row {
            opacity: 1.0; }
        .main-appt-availability-content {
            background: #fff; } }

    .heading-row {
        height: 40px;
        background: linear-gradient(90deg, #333333 0%, #555555 100.67%);
        border-radius: 12px 12px 0px 0px;
        opacity: 0.6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 20px;
        color: #fff;

        .title {
            font-size: 16px;
            font-weight: 500;
            display: flex;
            align-items: center;

            .multi-session-icon {
                margin-right: 5px; } }

        .MuiButtonBase-root {
            color: #fff;
            font-size: 14px; } }

    .main-appt-availability-content {
        background: #fafafa;
        border-radius: 0 0 12px 12px;
        position: relative;
        display: flex;

        .new-workflow-snackbar {
            position: absolute;
            background: #E8F8F5;
            bottom: 10px;
            left: 190px;
            width: calc(100% - 200px);
            //transform: translateY(calc(100% + 20px))
            animation: slide-up 0.3s ease-in;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            border: 1px solid #C0EBE3;
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
            border-radius: 4px;

            .icon-and-text {
                display: flex;
                align-items: center;

                .MuiSvgIcon-root {
                    color: #00B18F;
                    font-size: 36px;
                    margin-right: 10px; }

                .text {
                    //width: 480px
                    .main-line {
                        font-weight: 500;
                        font-size: 15px; }

                    .sub-line {
                        font-size: 12px; } } } }

        .image-area {
            background: #f5f5f5;
            width: 180px;
            height: 120px;
            border-radius: 0 0 0 12px;

            img {
                width: 180px;
                height: 120px; } }

        .on-web-indicator {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 10px;
            left: 10px;
            border-radius: 50%;
            background: rgba(255,255,255, 0.8);
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
            width: 36px;
            height: 36px;

            &:hover {
                .MuiSvgIcon-root {
                    opacity: 1; }
                .crossout {
                    opacity: 0.8; } }

            .MuiSvgIcon-root {
                color: #ccc;
                font-size: 32px;
                opacity: 0.7;
                transition: all 0.2s;

                &.web-enabled {
                    opacity: 1;
                    color: #00B18F; } }

            .crossout {
                position: absolute;
                pointer-events: none;
                width: 36px;
                height: 4px;
                border-radius: 2px;
                background: #E56D74;
                opacity: 0.5;
                transform: rotate(45deg);
                transition: all 0.2s; } }

        .on-web-switch {
            position: absolute;
            bottom: 10px;
            left: 3px;
            background: #fff;
            margin: 0;
            border-radius: 20px;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
            transform: translateY(60px);
            transition: transform 0.3s;

            &.editing {
                transform: translateY(0px); }

            .MuiTypography-body1 {
                font-size: 13px;
                margin-right: 10px; } }

        .appt-availability-details {
            padding: 10px 20px 10px 10px;
            width: calc(100% - 20px);
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .top-line {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .description {
                    font-size: 13px;
                    line-height: 21px; }

                .available-date-range {
                    .change-date-range-button {
                        text-transform: none;
                        font-weight: 400;
                        padding: 0; } } }

            .bottom-line {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 13px;

                .calendar {
                    display: inline-flex;
                    align-items: center;
                    .calendar-name {
                        font-weight: 500; } } } }

        .configure-and-message {
            display: flex;
            align-items: center;
            padding: 0 10px;
            height: 60px;
            background: #fff;
            box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.08);
            border-radius: 0px 0px 8px 0px;
            position: absolute;
            bottom: 0;
            right: 0;
            width: calc(100% - 180px);
            transform: translateY(0%);
            transition: transform 0.3s;

            &.editing {
                transform: translateY(100%); }

            .configure-button {
                white-space: nowrap;
                width: 220px; }

            .configure-message {
                font-size: 12px;
                margin-left: 20px;
                width: calc(100% - 240px); } }

        .editing-bottom-bar {
            position: absolute;
            bottom: 0;
            height: 60px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            bottom: -60px;
            padding: 0 10px;
            right: 0;
            width: calc(100% - 180px);
            transition: bottom 0.3s;
            box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.08);

            &.editing {
                bottom: 0; }

            .MuiInputLabel-outlined {

                transform: translate(14px, 11px) scale(1);
                &.MuiInputLabel-shrink {
                    transform: translate(14px, -4px) scale(0.75); } }

            .MuiFormLabel-root {
                font-size: 12px; }

            .MuiSelect-root {
                height: 21px;
                line-height: 21px;
                padding: 7px 32px 7px 10px; }

            .save-button {
                font-size: 14px;
                width: 120px;

                .MuiCircularProgress-root {
                    color: #888; } } } } }

.appointment-availability-limit-dialog {
    font-family: Roboto, sans-serif;
    .heading-row {
        height: 40px;
        background: linear-gradient(90deg, #333333 0%, #444444 100%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 20px;
        color: #fff;

        .title {
            font-size: 16px;
            font-weight: 500; } }

    .main-explanation {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px; }

    .explanation {
        max-width: 540px;
        font-size: 13px;
        margin-bottom: 10px; }

    .calendars {
        display: flex;
        align-items: center;
        justify-content: center;

        .start-calendar {
            margin-right: 10px; }
        .end-calendar {
            margin-left: 10px; }

        .start-calendar, .end-calendar {
            width: 260px;
            box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.15), -1px 0 2px rgba(0,0,0,0.08);
            padding-bottom: 10px;
            border-radius: 8px;

            .calendar-heading {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 13px;
                font-weight: 300;
                background: #eee;
                padding: 0 15px;
                margin-bottom: 5px;
                border-radius: 8px 8px 0px 0px;

                .focused-date-value {
                    font-weight: 500;

                    .date-and-cancel {
                        margin-right: -10px;
                        display: flex;
                        align-items: center;

                        .MuiIconButton-root {
                            padding: 5px; } } } } } }

    .MuiDialogActions-root {
        padding: 10px 20px;

        .MuiButton-root {
            font-size: 15px; } } }

.available-date-range {
    display: flex;
    align-items: center;
    font-size: 13px;
    .MuiSvgIcon-root {
        color: #00B18F;
        margin: 4px 8px 4px 4px; }

    &.editing {
        .MuiIconButton-root {
            padding: 4px;
            margin-right: 4px;
            background: rgba(26,132,255,0.1); }

        .MuiSvgIcon-root {
            color: #1A84FF;
            margin: 0; } }


    .prefix {
        font-size: 11px;
        font-weight: 300;
        margin-right: 3px;
        margin-top: 1px; }

    .dates {
        font-weight: 500; } }


.multi-session-appt-type-configure-dialog {
    font-family: Roboto, sans-serif;

    .configure-heading {
        height: 40px;
        background: linear-gradient(90deg, #333333 0%, #444444 100%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 20px;
        color: #fff;
        min-width: 600px;
        font-size: 16px;
        font-weight: 500; }

    .subheading {
        font-size: 13px;
        padding: 20px 20px 0 20px;
        max-width: 800px; }

    .appointment-blocks-wrapper {
        margin: 20px 20px 0 20px;
        min-width: 700px; }

    .appointment-block-selector {
        min-height: 100px; }

    .appointment-blocks-table {
        .appointment-block-selector.from-existing {
            margin-bottom: 0; } }

    .MuiDialogActions-root {
        padding: 0 20px 10px 20px;
        align-items: center;

        .MuiButton-root {
            font-size: 16px; } } }

@keyframes slide-up {
    from {
        transform: translate(0, calc(100% + 20px)); }
    to {
        transform: translateY(0, 0%); } }
