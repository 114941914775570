.appointments-content {
    display: flex;
    padding: 0 !important;
    position: relative;

    .left-bar {
        width: 250px;
        background: #f7f8f9;
        border-right: 1px solid #eee;
        border-bottom-left-radius: 6px; }

    .heading {
        padding: 0 20px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-size: 16px;
            font-weight: 500; } }

    .agenda-content {
        width: calc(100% - 251px);
        padding: 0 20px; } }

@media print {
    .connect-page {
        margin-top: 0;
        padding: 0 !important; }

    .workflow-scheduling-page {
        box-shadow: 0px 0px 0px #fff;
        .workflow-top-bar {
            display: none; }
        .workflow-content {
            height: auto !important;
            border: 0; } }

    .appointments-content {
        .left-bar {
            display: none; }

        .agenda-content {
            width: 100%;
            padding: 0; } } }

