.appointment-types-page {
    font-family: Roboto, sans-serif;
    position: relative;
    margin: 0 20px;
    height: calc(100vh - 80px);

    .appointment-types-content {
        min-width: 600px;
        max-width: 900px;
        margin: 80px auto 0 auto;
        height: 100%;

        .page-loading {
            position: absolute;
            left: calc(50% - 20px);
            top: calc(50% - 20px); }


        .heading {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon-and-text-filter {
                display: inline-flex;
                align-items: center; }

            .appt-types-name-filter {
                margin-left: 5px;
                width: 300px;
                box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);

                .MuiOutlinedInput-input {
                    padding: 10px 10px;
                    font-size: 14px; } }

            .appt-types-category-filter {

                .MuiOutlinedInput-input {
                    padding: 10px 32px 10px 20px;
                    font-size: 14px;
                    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
                    color: #555; } } }

        .appointment-type-rows {
            height: calc(100% - 60px);

            .ReactVirtualized__Grid {
                outline: none; }

            .no-matching-appt-types-message {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                color: #888;
                margin-top: 80px;

                .MuiSvgIcon-root {
                    font-size: 64px;
                    margin-right: 10px;
                    color: #aaa; }


                .text {
                    .main-line {
                        font-size: 18px; }
                    .sub-line {
                        font-size: 13px; } } } } } }
