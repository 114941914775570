.workflow-top-bar {
    background: #f6f7f8;
    height: 80px;
    border: 1px solid #e5e5e5;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    justify-content: space-between;

    .left {
        position: relative;
        display: flex;

        .back-button {
            position: absolute;
            top: 50%;
            right: 100%;
            transform: translate(50%, -50%);
            border: 1px solid #ddd;
            padding: 8px; }

        .workflow-image {
            border-top-left-radius: 6px;
            margin-right: 10px; }

        .title-and-waitlist-or-invite {
            padding: 7px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .title {
                font-size: 18px;
                font-weight: 500;
                color: #555;
                //margin-bottom: 5px
                &.full-height {
                    height: 78px;
                    display: inline-flex;
                    align-items: center; } }

            .waitlist-button {
                background-color: #00B18F;
                color: #fff;
                text-transform: none;
                border-radius: 20px;
                padding: 4px 14px; }

            .manage-waitlist-link-button {
                text-transform: none;
                color: #1A84FF;
                font-weight: 400;
                font-size: 12px; } } }


    .right {
        display: inline-flex;
        margin-right: 10px;
        position: relative;

        .tab-background {
            pointer-events: none;
            position: absolute;
            top: 10px;
            margin-left: 5px;
            width: 90px;
            height: 69px;
            border: 1px solid #ACD2FF;
            border-bottom: 1px solid transparent;
            background: rgba(26,132,255, 0.1);
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            transition: all 0.3s ease-in; }

        .tab {
            margin: 10px 5px 0 5px;
            display: block;
            width: 90px;
            height: 69px;
            border: 1px solid transparent;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            cursor: pointer;
            transition: all 0.3s ease-in;

            .MuiSvgIcon-root {
                font-size: 32px;
                color: #aaa; }

            .clipboard-clock-path {
                fill: #aaa; }

            .label {
                font-size: 12px;
                color: #888; }

            &.selected {
                //border: 1px solid #82BCFF
                //border-bottom: 1px solid transparent
                //background: #EBF4FF
                background-position: 0 100%;
                cursor: default;

                .label {
                    color: #1A84FF;
                    font-weight: 500; }

                .clipboard-clock-path {
                    fill:  #1A84FF; }

                .MuiSvgIcon-root {
                    color: #1A84FF; } } } } }



