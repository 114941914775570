.map-file-attributes-content {
    .heading {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        height: 35px;

        .text {
            margin-left: 20px; } }

    .tabs-and-content {
        display: flex; }

    .required-fields {
        border-right: 1px solid #ddd;

        .tabs-heading {
            height: 40px;
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 500;
            padding: 0 10px; }


        .MuiTabs-root {
            width: 260px;

            .MuiTab-root {
                background: #fafafa;
                transition: background 0.3s;
                border-top: 1px solid #ddd;

                .optional-marker {
                    font-size: 11px;
                    text-transform: none;
                    color: #555; }

                .mapped-tab-content {
                    display: flex;
                    align-items: center;
                    flex-direction: row;

                    .MuiSvgIcon-root {
                        position: absolute;
                        left: 10px;

                        margin-right: 10px;
                        color: #007E66; }

                    .name-and-column {
                        display: flex;
                        flex-direction: column;

                        .column-line {
                            text-transform: none;
                            font-size: 12px;
                            max-width: 180px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis; } } } }

            .Mui-selected {
                background: #B4D7FE; } }

        .configured-tab-label {
            display: flex;
            align-items: center;

            .left, .right {
                width: 21px;
                height: 21px; }

            .left {
                color: #00B18F; }

            .middle {
                margin: 0 5px; }

            .col {
                background: #52A1FC;
                text-transform: none;
                color: #ffffff;
                border-radius: 4px;
                padding: 0 4px; }
            &.unmapped {
                .col {
                    background: transparent;
                    color: inherit; } } } }

    .focused-attribute-content {
        .column-header {
            height: 41px;
            border-bottom: 1px solid #ddd;
            font-size: 15px;
            font-weight: 500;
            padding: 0 10px;
            display: flex;
            align-items: center; }

        .column-options-wrapper {
            min-height: 250px;
            min-width: 440px;
            overflow-y: scroll;
            .column-options {
                display: flex;
                flex-wrap: wrap;
                padding: 10px;

                .csv-column-summary {
                    margin: 5px; } } } }

    .actions {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        padding-top: 10px;

        .auto-mapping-alert {
            position: absolute;
            font-size: 13px;
            font-weight: 500;
            bottom: -5px;

            .MuiAccordionSummary-root.Mui-expanded {
                min-height: 50px;
                height: 50px; }

            .MuiAccordionDetails-root {
                display: block;
                max-width: 255px;
                font-size: 11px;
                background: #FFF9C1;
                line-height: 20px; }

            .MuiAccordion-root {
                background: #fff59d; }

            .MuiIconButton-root {
                padding: 4px; }

            .highlight-icon {
                margin-right: 10px; } }

        .auto-add-unknown-patients-switch {
            background: #f5f5f5;
            border: 1px solid #ddd;
            padding: 4px 15px 4px 10px;
            border-radius: 5px;
            margin-right: 40px;
            .MuiFormControlLabel-label {
                font-size: 12px;
                font-weight: 400; } } } }

