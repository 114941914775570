.assessments-filter-dropdown-content {
    font-family: Roboto, sans-serif;

    width: 300px;
    .explanation {
        font-size: 12px; }

    .controls {
        margin-top: 10px;

        .is-none {
            color: #888; }

        .MuiInputBase-root {
            margin-top: 10px; }

        .MuiOutlinedInput-input {
            padding: 12px 28px 12px 14px; }


        .controls-heading {
            font-size: 14px;
            font-weight: 500; }

        .custom-date-link {
            color: #1A84FF;
            //font-weight: 500
            text-decoration: none;

            &:hover {
                text-decoration: underline; }

            .label {
                color: #555;
                font-weight: 400;
 } }                //font-size: 12px

        .custom-date-range-select-value {
            .adornment-text {
                font-size: 11px;
                color: #555; }

            .MuiTextField-root {
                font-size: 12px;
                .MuiInputBase-root {
                    margin-top: 0; }
                .MuiInputBase-input {
                    padding: 0px;
                    width: 100px; }

                .MuiInput-underline:before, .MuiInput-underline:after {
                    border-bottom: 0; } } } } }

.custom-date-range-dialog {
    .custom-date-range-pickers {
        display: flex; } }

.assessments-active-label {

    .main-line {
        font-size: 12px;
        .interaction-type, .timeframe {
            font-size: 11px;
            font-weight: 400;

            .custom-date {
                font-weight: 500; } } } }
