.patients-selection-pane {
    position: relative;

    .patients-selection-pane-inner {
        height: 100%; }

    &.loading {

        .loading-message {
            position: absolute;
            left: 50%;
            bottom: 0px;
            transform: translate(-50%, 0%);
            z-index: 100;
            background: #00B18F;
            color: #fff;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            box-shadow: 5px 5px 20px rgba(0,0,0,0.2);
            border-radius: 6px;
            font-size: 13px;

            .MuiCircularProgress-root {
                margin-right: 10px;
                color: #D1F1EB; } } }



    .patients-table-wrapper {
        min-width: 700px; } }

