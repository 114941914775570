.choose-bulk-message-dialog {
    font-family: Roboto, sans-serif;

    .MuiDialogTitle-root {
        padding: 16px 24px 6px 29px;

        h2 {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .close-icon {
                cursor: pointer;
                color: #888888;
                transition: color 0.3s;

                &:hover {
                    color: #000; } } } }

    .MuiDialogContent-root {

        .template-title {
            margin-bottom: 5px;
            display: flex;
            align-items: center;

            .search-text-field {
                margin-left: 5px;
                .MuiInputBase-input {
                    padding: 12px 14px 12px 0; } }

            .clear-icon {
                cursor: pointer; }

            .counts {
                margin-left: 10px;
                font-size: 13px;
                color: #888; } }

        .grid-wrapper {
            margin-bottom: 20px;
            overflow: hidden; } } }


