.configure-content {
    display: flex;
    padding: 0 !important;
    position: relative;

    .left-bar {
        width: 230px;
        background: #f6f7f8;
        border-right: 1px solid #ddd;
        height: 100%;
        border-bottom-left-radius: 6px;

        .left-bar-heading {
            height: 50px;
            display: flex;
            align-items: center;
            padding: 0 20px;
            font-size: 14px;
            font-weight: 500;
            color: #888; }

        .left-bar-option {
            height: 50px;
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 500;
            color: #888888;
            padding: 0 20px;
            background: #fff;
            border-bottom: 1px solid #ddd;
            cursor: pointer;
            transition: all 0.3s;

            &.selected {
                color: #000000;
                background: #D9EAFF; } } }

    .main-configure-content {
        width: calc(100% - 230px);

        .configure-appointment-scheduling-content {
            padding: 30px;

            .heading {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 10px; }

            .scheduling-option {
                display: flex;
                padding: 10px 10px 20px 10px;
                margin-bottom: 10px;
                opacity: 0.6;
                border-left: 5px solid transparent;
                cursor: pointer;
                transition: all 0.15s ease-in-out;

                .config-options {
                    pointer-events: none; }

                &:hover {
                    background: #EBF4FF;
                    opacity: 0.8; }

                &.selected {
                    opacity: 1;
                    border-left: 5px solid #00B18F;
                    //box-shadow: 2px 2px 4px rgba(0,0,0,0.05)
                    cursor: default;

                    &:hover {
                        background: #fff; }


                    .config-options {
                        pointer-events: all; } }

                .radio-area {
                    width: 40px; }

                .text {
                    .main-line {
                        display: flex;
                        height: 40px;
                        align-items: center;
                        font-size: 15px;
                        font-weight: 500; }

                    .sub-line {
                        font-size: 13px;
                        margin-bottom: 20px;
                        margin-top: 10px; }

                    .additional-configuration {
                        .explain-config {
                            font-size: 13px;
                            margin-bottom: 20px; }

                        .config-options {
                            display: flex;
                            .calendar-select {
                                margin-right: 40px; } } } } }

            .buttons {
                display: flex;
                justify-content: flex-end; } }

        .configure-messaging-templates-content {
            padding: 20px;
            .heading {
                display: flex;
                //justify-content: space-between
                align-items: baseline;
                margin-bottom: 10px;

                .title {
                    font-size: 18px;
                    font-weight: 500; }

                .scheduling-method {
                    font-size: 14px;
                    margin-left: 20px;
                    color: #333; } }

            .messages-and-preview {
                display: flex;

                .message-accordions {
                    width: calc(100% - 320px);
                    margin-right: 20px;

                    .accordion-title {
                        display: inline-flex;
                        align-items: center;
                        .MuiSvgIcon-root {
                            margin-right: 10px; } }

                    .buttons {
                        display: flex;
                        justify-content: flex-end; } }

                .preview {
                    .phone-heading {
                        max-width: 300px;
                        padding: 0 20px;
                        text-align: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: #aaa;
                        margin: 10px 0; } } } } } }
