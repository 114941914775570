.upgrades-settings-page {
    padding: 15px 30px;
    font-family: Roboto, sans-serif;

    .upgrades-heading {
        color: #4a4a4a;
        margin-bottom: 20px;
        .main-line {
            font-size: 20px; }

        .sub-line {
            font-size: 14px;
            color: #9b9b9b; } } }
