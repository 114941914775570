.filtering-option-group {
    font-family: Roboto, sans-serif;
    background: #fff;
    .filtering-option {
        display: flex;
        height: 40px;
        align-items: center;
        cursor: pointer;
        padding: 0 10px;

        &:hover, &:focus {
            outline-color: #83BDFD;
            color: #1A84FF;
            .icon {
                color: #1A84FF; } }

        &.selected {
            background: #D6E9FE;
            color: #1A84FF;
            border-radius: 4px;
            .icon {
                color: #1A84FF; } }

        .icon {
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
            color: #888; } } }
