.insert-menu-button.MuiIconButton-root {
    padding: 3px; }

.attachment-option {

    &:hover {
        .MuiSvgIcon-root {
            color: #333; } }

    label {
        display: flex;
        align-items: center; }

    .MuiSvgIcon-root {
        color: #888;
        margin-right: 5px;
        transition: all 0.3s; } }

.choose-appt-type-to-insert-dialog {
    .MuiDialogTitle-root {
        h2 {
            min-width: 610px;
            display: flex;
            justify-content: space-between; } }

    .MuiDialogContent-root {
        padding: 0px 14px 20px 24px;

        .no-appointments-configured {
            margin: 30px auto;
            text-align: center;
            font-size: 13px;
            .MuiButtonBase-root {
                padding: 0; } } }

    .ReactVirtualized__Grid {
        outline: none; }

    .explanation {
        font-size: 12px;
        margin-bottom: 10px;
        width: 610px; }

    .appt-type-row {
        .appt-type-row-inner {
            display: flex;
            align-items: center;
            border: 1px solid #e6e6e6;
            box-shadow: 2px 2px 5px rgba(0,0,0,0.08);
            border-radius: 8px;
            width: calc(100% - 10px);
            cursor: pointer;
            background: #fff;
            transition: all 0.3s;

            &:hover {
                background: #EAF4FF;
                box-shadow: 2px 2px 8px rgba(0,0,0,0.15), -1px -2px 2px rgba(0,0,0,0.03); }

            img {
                border-radius: 8px 0px 0px 8px; }

            .text {
                display: flex;
                //border: 1px solid #e6e6e6
                border-left: 0;
                border-radius: 0 8px 8px 0;
                width: calc(100% - 85px);
                flex-direction: column;
                height: 78px;
                padding-left: 10px;
                justify-content: space-around;
                .label {
                    font-size: 15px;
                    font-weight: 500; }
                .description {
                    font-size: 12px;
                    font-weight: 400; } } } } }
