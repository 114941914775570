.connect-page {
    margin-top: 80px;
    font-family: Roboto, sans-serif;
    padding: 10px 20px 0 20px;
    height: calc(100% - 130px);

    .connect-title-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        padding: 0 5px;
        margin-bottom: 20px;
        margin-top: 10px;

        .title {
            font-size: 20px;
            font-weight: 500; }

        .subtitle {
            font-size: 13px;
 } }            //margin-bottom: 10px

    .grid-wrapper {
        margin: auto; } }

.bulk-message-status-snackbar {
    max-width: 900px;
    .MuiPaper-root {
        background: #98CB9B;

        .bulk-message-status-banner {
            color: #000000;
            display: flex;
            align-items: center;

            .phone-icon {
                font-size: 36px;
                margin-right: 10px; }

            .image-box {
                border-radius: 5px;
                margin-right: 15px; }

            .text {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .main-line {
                    font-size: 14px;
                    font-weight: 500; }
                .sub-line {
                    font-size: 11px; } } } }

    &.failed {
        .MuiPaper-root {
            background: #EE9BA0; } } }

