.patient-appointments {
    position: relative;
    font-family: Roboto, sans-serif;
    width: 700px;
    height: 335px;

    &.loading {
        .MuiCircularProgress-root {
            position: absolute;
            left: calc(50% - 22px);
            top: 100px; } }

    &.empty {
        .empty-message {
            text-align: center;
            position: absolute;
            left: 50%;
            top: 100px;
            font-size: 18px;
            white-space: nowrap;
            color: #888;
            transform: translateX(-50%);

            .sub-line {
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;

                .MuiButton-root {
                    padding: 0;
                    text-transform: none;
                    font-size: 18px; } } } } }
