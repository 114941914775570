.patient-name-search {
    .search-and-count {
        display: flex;
        align-items: center; }

    .search-text-field {
        margin-right: 20px;
        .MuiOutlinedInput-input {
            padding: 12px 14px; } }

    .table-wrapper {
        margin-top: 10px; } }
