.appointment-row {
    background: #fafafa;
    //border: 1px solid #e6e6e6
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    border-radius: 27px 6px 6px 27px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.07), -1px -2px 2px rgba(0, 0, 0, 0.03);

    margin-bottom: 10px;

    &.with-patient-info {
        border-top-left-radius: 27.5px;
        border-bottom-left-radius: 27.5px; }

    .date-and-time {
        width: 180px;
        margin-left: 20px;
        font-size: 15px;
        font-weight: 400;

        .date-line {
            font-size: 16px;
            font-weight: 500; } }

    .avatar-and-patient-info {
        display: flex;
        align-items: center; }

    .patient-and-time-info {
        margin-left: 10px;

        .top-line {
            white-space: nowrap; }

        .patient-name {
            display: inline-block;
            font-size: 18px;
            color: #1A84FF;
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis; }

        .appointment-time {
            font-size: 16px;
            font-weight: 500;
            display: inline-block;
            text-align: right;
            width: 120px; }

        .bottom-line {
            display: flex;
            justify-content: space-between;
            font-size: 13px;
            color: #333; } }

    .appointment-calendar-and-vaccine-type {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: flex-start;
        margin-left: 6px;

        .appointment-calendar {
            background: #eeeeee;
            display: flex;
            align-items: center;
            padding: 0 10px;
            border: 1px solid #dddddd;
            border-radius: 6px;
            height: 40px;
            margin: 0 10px;
            min-width: 170px;

            .MuiSvgIcon-root {
                margin-right: 5px; }

            .appointment-type-and-calendar-name {
                display: flex;
                flex-direction: column;
                justify-content: space-around; }

            .appointment-type {
                font-size: 12px;
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 120px; }

            .calendar-name {
                font-size: 11px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 120px; } }

        .vaccine-type {
            font-size: 12px;
            font-weight: 500;
            display: flex;
            align-items: center;

            .vaccine-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 79px; } } }


    .controls {
        margin-right: 10px;
        white-space: nowrap;
        width: 165px;

        &.loading {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            color: #555;

            .MuiCircularProgress-root {
                margin-right: 5px; } }

        .edit-button {
            margin-right: 10px; }

        &.checked-in {
            display: flex;
            align-items: center;

            .MuiSvgIcon-root {
                color: #00B18F;
                margin-right: 5px;
                font-size: 30px; }

            .text {
                .main-line {
                    font-size: 12px;
                    display: inline-flex;
                    align-items: center;
                    font-weight: 500;

                    .MuiButtonBase-root {
                        color: #1A84FF;
                        text-transform: none; }
                    .MuiButton-text {
                        padding: 0;
                        font-size: 12px;
                        font-weight: 400; }


                    .unchecking-in-progress {
                        margin-left: 30px; } }



                .sub-line {
                    font-size: 11px;
                    font-weight: 300; } } } }


    &.past {
        //opacity: 0.8
        .appointment-time {
            color: #888; }
        .patient-avatar {
            opacity: 0.5; }

        .bottom-line {
            color: #888; }

        .appointment-calendar {
            opacity: 0.65; } }

    &.in-progress {
        background: #EAF4FF;
        border-color: #C1DDFF; } }

.appointment-checkin-dialog {
    font-family: Roboto, sans-serif;

    .patient-and-appt-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
        background: #f6f7f8;
        padding: 10px 20px;
        border: 1px solid #e6e6e6;
        border-radius: 6px;

        .patient-name {
            .name {
                font-size: 14px;
                font-weight: 500; } } }

    .explanation {
        font-size: 13px; } }
