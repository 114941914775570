.appointments-agenda {
    position: relative;
    overflow-y: hidden;

    .heading {
        .shown-in-local-time-message {
            font-size: 11px;
            background: #eee;
            padding: 5px 10px;
            border-radius: 4px; }

        .print-label {
            display: none; } }

    .print-appointment-rows {
        display: none;
        width: 100%;
        border-collapse: collapse;


        th {
            background: #eee; }

        td, th {
            border: 1px solid #e6e6e6;
            padding: 5px;
            font-size: 12px; }

        .time {
            font-weight: 500; } }

    .appointments {
        height: calc(100% - 50px);
 }        //overflow-y: scroll

    .appointment-rows.loading {
        .MuiCircularProgress-root {
            position: absolute;
            top: 150px;
            left: calc(50% - 20px); } }

    .active-appointment-indicator, .next-appointment-indicator {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #555;
        margin-bottom: 5px;
        margin-top: -5px;

        .MuiSvgIcon-root {
            color: #888; }
        .now-indicator-line {
            height: 1px;
            border-top: 2px dashed #ddd;
            display: inline-block;
            width: calc(100% - 135px); } }

    .next-appointment-indicator {
        .now-indicator-line {
            width: calc(100% - 120px); } }

    .no-appointments-message {
        margin: 40px 60px;
        color: #333;

        display: flex;
        align-items: center;

        .MuiSvgIcon-root {
            font-size: 64px;
            color: #555;
            margin-right: 15px; }

        .text {
            .main-line {
                font-size: 16px;
                margin-bottom: 10px;
                font-weight: 500; }
            .sub-line {
                font-size: 13px;
                .MuiButtonBase-root {
                    margin: 0 5px; }

                .MuiButton-label {
                    text-transform: none;
                    color: #1A84FF;
                    font-size: 13px; }
                .MuiButton-text {
                    padding: 0; } } } } }

@media print {
    .appointments-agenda {
        overflow-y: auto;
        height: auto !important;

        .heading {
            .short-label {
                display: none; }
            .print-label {
                display: inline; } }




        .appointments {
            padding: 0 !important;

            .appointment-rows {
                display: none; }

            .print-appointment-rows {
                display: table; } } } }
