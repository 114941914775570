.patients-list {
    .remove-cell {
        text-align: right; }

    .ReactVirtualized__Grid {
        outline: none;
        border: 1px solid #ddd;
        font-size: 13px; }

    .ReactVirtualized__Table__headerRow {
        border-bottom: 1px solid #ddd;
        box-shadow: 0px 1px 1px rgba(0,0,0,0.05);
        background: #fff !important; }

    .ReactVirtualized__Table__row {
        border-bottom: 1px solid #ddd;
        outline: none;
        opacity: 1;
        transition: opacity 0.1s; }

    .ReactVirtualized__Table__rowColumn {
        display: flex;
        align-items: center; }

    .MuiIconButton-root {
        padding: 8px; } }
