.multiselect-sidebar {
    width: 360px;
    margin: 39px 0 0 20px;
    padding: 0px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.1);
    z-index: 10;
    position: absolute;
    right: 0;

    .heading {
        font-size: 16px;

        display: flex;
        align-items: center;
        padding: 10px 20px 0 20px;
        font-weight: 500;
        justify-content: space-between;

        .default-heading-wrapper {
            width: 100%;
            align-items: baseline;
            display: flex;
            justify-content: space-between; }


        .messagable-count {
            font-size: 13px;
            margin-left: 10px;
            font-weight: 400; } }

    .none-selected-message {
        font-size: 13px;
        text-align: center;
        padding: 42px 20px;
        color: #888;
        background: #eee; }

    .table-wrapper {
        padding: 10px 20px 10px 20px;
        height: calc(100% - 100px); }



    .actions-column {
        justify-content: flex-end; }

    .sidebar-actions {
        margin-top: 10px;
        text-align: right;

        .unselect-button {
            margin-right: 20px;
            .MuiButton-label {
                color: #3793ff; }
            &.Mui-disabled {
                .MuiButton-label {
                    color: #888; } } } } }

