.date-time-select-content {
    padding: 0 20px;
    position: relative;
    .heading {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-size: 16px;
            font-weight: 500; }

        .calendar-select {
            min-width: 270px;

            .name {
                font-size: 13px; }

            .MuiOutlinedInput-input {
                padding: 10px; } } }

    .calendar-and-time-slots {
        display: flex;
        width: 550px;

        .calendar-and-days {
            width: 50%;
            //border: 1px solid #e6e6e6
            border-bottom: 0;


            .days-and-counts {
                border-top: 1px solid #e6e6e6;
                .day-of-week-row {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #e6e6e6;
                    padding: 0 20px;
                    cursor: pointer;
                    transition: all 0.15s;

                    &.odd {
                        background: #fafafa; }

                    &:hover {
                        background: #EBF4FF; }

                    &.focused {
                        background: #1A84FF;
                        color: #fff; }


                    .date {
                        font-size: 13px;
                        font-weight: 500; }

                    .available-count {
                        background: #00B18F;
                        border-radius: 50%;
                        color: #fff;
                        display: inline-flex;
                        width: 25px;
                        height: 25px;
                        font-size: 12px;
                        align-items: center;
                        justify-content: center; }

                    &.focused {
                        .available-count {
                            background: #46C6AE; } }



                    &.is-in-past {
                        cursor: default;
                        background: #fff;
                        .date {
                            font-size: 13px;
                            text-decoration: line-through;
                            font-weight: 300; }

                        .available-count {
                            opacity: 0; }

                        &.odd {
                            background: #fafafa; } } } } }


        .time-slots {
            width: 50%;
            border-left: 1px solid #e6e6e6;
            boder-top: 0;



            .time-slots-heading {
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 300;
                font-size: 15px;

                .date {
                    font-weight: 500; } }

            .timezone-mismatch {
                padding: 10px 20px;
                background: #f6f7f8;
                .prefix {
                    font-weight: 300;
                    font-size: 11px; }

                .timezone-display-select {
                    font-size: 12px;
                    background: #fff;

                    .MuiOutlinedInput-input {
                        padding: 8px 32px 8px 8px; } } }


            .time-slot-buttons {
                padding: 5px 10px;
                height: 510px;
                overflow-y: scroll;
                position: relative;
                //border-top: 1px solid #e6e6e6
                //border-bottom: 1px solid #e6e6e6



                .MuiButton-root {
                    margin-bottom: 5px;
                    position: relative;

                    .number-of-slots-at-time {
                        position: absolute;
                        background: rgba(0,0,0,0.3);
                        border-radius: 50%;
                        font-size: 12px;
                        color: #fff;
                        width: 21px;
                        height: 21px;
                        right: 20px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        transition: all 0.3s;

                        &:hover {
                            background: rgba(0,0,0,0.5); } } }


                .loading-slots-message {
                    position: absolute;
                    left: calc(50% - 15px);
                    top: 100px;
                    z-index: 1000; } } } } }

