.message-status-icon {
    position: relative;

    .MuiSvgIcon-root {
        color: #aaa; }

    &.has-unread-messages {
        .MuiSvgIcon-root {
            color: #1A84FF; } }

    .alert-circle {
        display: block;
        position: absolute;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #DF4851;
        right: 6px;
        bottom: 8px; } }
