
.reset-patient-targeting-button {
    .MuiSnackbarContent-root {
        background:  #27AE60; }

    &.failure {
        .MuiSnackbarContent-root {
            background: #DF4851; } } }
.reset-patient-opportunities-snackbar-message {
    color: #ffffff; }
