.review-recipients-and-message-content {
    position: relative;

    .patients-and-phone-preview {
        display: flex;
        position: relative;

        .patients-area {
            padding: 20px;
            width: calc(100% - 320px);

            .heading {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 10px; }

            .table-buttons {
                display: flex;
                justify-content: flex-end;
                margin-top: 10px;

                .unselect-button {
                    margin-right: 20px; } } }

        .message-preview {
            margin-top: 20px;

            .phone-heading {
                max-width: 300px;
                padding: 0 20px;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                color: #aaa;
                margin: 10px 0; } } }

    .warning-and-button-area {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .MuiButtonBase-root {
            height: 40px; }

        .too-many-patients-warning-outer {
            width: calc(100% - 300px);
            display: flex;
            justify-content: center;
            height: 54px;

            .too-many-patients-warning {
                display: flex;
                align-items: center;
                background: #FFF7AC;
                border-radius: 4px;
                box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
                padding: 10px 20px 10px 20px;

                .MuiSvgIcon-root {
                    margin-right: 10px;
                    font-size: 28px; }

                .text {
                    .main-line {
                        font-size: 14px;
                        font-weight: 500; }

                    .sub-line {
                        font-size: 11px; } } } } } }

.sending-error-snackbar {
    .MuiPaper-root {
        background: #F3616A; }

    .sending-error-message {
        display: flex;
        align-items: center;
        color: #fff;

        .MuiSvgIcon-root {
            font-size: 48px;
            margin-right: 10px; }

        .text {
            .main-line {
                font-size: 14px;
                font-weight: 500; }
            .sub-line {
                font-size: 11px;
                font-weight: 300; } }

        .ok-button {
            color: #fff; } } }
