.limit-booking-background {
    backdrop-filter: blur(1.5px);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;

    &.visible {
        opacity: 1; } }


.when-to-schedule-content {
    overflow: hidden;
    //position: relative

    .heading {
        font-size: 17px;
        font-weight: 500;
        margin-bottom: 10px;
 }        //margin-top: 20px

    .sub-heading {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 20px; }

    .general-booking-controls {
        display: flex;
        align-items: center;
        justify-content: space-between; }

    .calendars {
        display: flex;
        align-items: center;
        justify-content: center;

        .start-calendar {
            margin-right: 10px; }
        .end-calendar {
            margin-left: 10px; }

        .start-calendar, .end-calendar {
            width: 260px;
            box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.15), -1px 0 2px rgba(0,0,0,0.08);
            padding-bottom: 10px;
            border-radius: 8px;

            .calendar-heading {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 13px;
                font-weight: 300;
                background: #eee;
                padding: 0 15px;
                margin-bottom: 5px;
                border-radius: 8px 8px 0px 0px;

                .focused-date-value {
                    font-weight: 500;

                    .date-and-cancel {
                        margin-right: -10px;
                        display: flex;
                        align-items: center;

                        .MuiIconButton-root {
                            padding: 5px; } } } } } }

    .calendar-and-length-selects {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .calendar-select {
            margin-right: 40px; }

        .on-web-switch {
            margin-left: 40px;

            .MuiFormControlLabel-label {
                white-space: nowrap; } } }

    .limit-booking-area {
        position: absolute;
        bottom: 15px;
        left: 40px;

        .change-when-bookable-button {
            text-transform: none; } }

    .limit-booking-wrapper {

        .heading {
            margin-top: 0; }

        .main-heading {
            font-size: 17px;
            font-weight: 500;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between; } }

    .calendars-and-slots {
        display: flex;
        .from-date-and-time, .to-date-and-time {
            width: 240px;
            border: 1px solid #e6e6e6;

            .heading {
                background: #f0f0f0;
                height: 40px;
                display: flex;
                align-items: center;
                padding: 0 10px;
                font-size: 14px; } }

        .to-date-and-time {
            margin: 0 10px; }

        .slots-overview {
            overflow: hidden;
            transition: width 0.2s;
            background: #fff;
            position: relative;

            .heading {
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px 0 20px;
                background: #00B18F;
                color: #fff;
                margin-bottom: 0;
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;

                .title {
                    font-size: 15px;
                    font-weight: 500;
                    white-space: nowrap;
                    max-width: 240px;
                    overflow: hidden;
                    text-overflow: ellipsis; }

                .total-available-slots {
                    font-size: 12px;
                    color: #fff;
                    margin-right: 10px; } }

            .day-slots-list {
                background: #f6f7f8;
                .days-virt-list {
                    background: #fff;
                    outline: none;

                    &.empty {
                        background: transparent; } }

                .slots-day-row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #e6e6e6;
                    height: 40px;
                    padding: 0 20px;

                    &.odd {
                        background: #fafafa; }

                    .date {
                        font-size: 14px;
                        display: flex;
                        align-items: center;

                        .vacation-icon {
                            margin-left: 5px; } }

                    .availability {
                        font-size: 12px;
                        font-weight: light;
                        color: #333;
                        display: flex;
                        align-items: center; } } }

            .loading-spinner {
                position: absolute;
                top: calc(50% - 20px);
                left: calc(50% - 20px);
                z-index: 1001; }

            .pick-days-message {
                position: absolute;
                top: 140px;
                font-size: 16px;
                color: #888;
                width: calc(100% - 80px);
                margin: 0 40px;
                text-align: center; } } }

    .buttons-row {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        .next-button {
            font-size: 15px; } } }

@keyframes slide-up {
    from {
        transform: translate(0%, 100%); }
    to {
        transform: translateY(0%, 0%); } }
