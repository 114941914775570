#editUserDialog {
    font-family: Roboto, sans-serif;

    .MuiDialogActions-root {
        padding: 8px 0; }

    .upcoming-appointments-warning {
        display: flex;
        align-items: center;
        background: #FFF7AC;
        border-radius: 6px;
        font-size: 14px;
        padding: 10px 20px;
        box-shadow: 2px 2px 4px rgba(0,0,0,0.075);

        .MuiSvgIcon-root {
            font-size: 32px;
            margin-right: 10px;
            color: #555; } } }
