.review-patients-content {
    position: relative;

    .error-message {
        position: absolute;
        bottom: 0;
        left: 50%;
        background: #DF4851;
        display: flex;
        align-items: center;
        transform: translate(-50%, 0%);
        padding: 10px;
        color: #fff;
        box-shadow: 5px 5px 10px rgba(0,0,0,0.1);

        .MuiSvgIcon-root {
            font-size: 42px;
            margin-right: 10px; }


        .text {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            white-space: nowrap;
            margin-right: 10px;
            .main-line {
                font-size: 14px;
                font-weight: 500; }

            .sub-line {
                font-size: 11px; } }

        .error-ok-button {
            color: #fff; } }


    &.error {
        .main-content-wrapper {
            pointer-events: none;
            opacity: 0.25; } }

    .heading {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        height: 35px;

        .text {
            margin-left: 20px; } }

    .population-stats {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        .operator {
            font-size: 30px;
            color: #666;
            margin: 0 20px; }

        .population-arithmetic, .population-result {
            display: flex;
            border: 1px solid #ACD2FF;
            background: #EAF4FF;
            padding: 10px 20px;
            border-radius: 6px; }

        .population-statistic {
            text-align: center;
            .number {
                font-size: 22px;
                font-weight: 500; }
            .label {
                font-size: 12px; } } }

    .table-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .search-and-counts {
            display: flex;
            align-items: center; }

        .search-text-field {
            margin-right: 20px;
            .MuiOutlinedInput-adornedEnd {
                padding-right: 6px; }
            .MuiInputBase-input {
                padding: 12px 10px 12px 0; }

            .clear-icon {
                cursor: pointer; } }

        .counts {
            font-size: 12px;
            font-weight: 300; }

        .label-and-type-filter-select {
            .label {
                font-size: 12px;
                font-weight: 300;
                margin-right: 5px; }
            .type-filter-select {
                .MuiOutlinedInput-input {
                    padding: 12px 32px 12px 14px;
                    font-size: 12px; } } } }

    .mapped-patients-table {
        .empty-table {
            font-size: 20px;
            color: #aaa;
            margin: 40px auto;
            text-align: center; }

        .ReactVirtualized__Table__row {
            border-bottom: 1px solid #e6e6e6;
            height: 30px;
            outline: none;
            box-sizing: border-box;
            font-size: 13px;

            .row-number-and-checkbox {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .row-number {
                    display: block;
                    width: 34px;
                    font-size: 11px;
                    color: #888;
                    text-align: center; }

                .patient-checkbox {
                    padding: 5px;
                    font-size: 30px;
                    color: #00B18F;
                    &.disabled {
                        font-size: 30px;
                        color: #888; } } } }

        .ReactVirtualized__Grid {
            outline: none;
            border: 1px solid #ddd; }

        .ReactVirtualized__Table__headerRow {
            border-bottom: 1px solid #ddd;
            box-shadow: 0px 1px 1px rgba(0,0,0,0.05);
            background: #fff !important; } }

    .actions {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;

        .auto-capitalize-toggle {
            cursor: pointer;
            font-size: 12px;
            margin-right: 10px;

            .MuiCheckbox-root {
                padding: 4px; } } } }

.non-specific-date-snackbar {
    .MuiSnackbarContent-root {
        color: #000;
        background: #fff59d;

        .non-specific-date-snackbar-content {
            display: flex;
            align-items: center;

            .MuiSvgIcon-root {
                font-size: 32px;
                margin-right: 10px; }

            .text {
                .main-line {
                    font-size: 14px;
                    font-weight: 500; }
                .sub-line {
                    font-size: 12px; } } } } }
