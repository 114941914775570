.assessed-attributes-dropdown-content {
    font-family: Roboto, sans-serif;
    width: 280px;

    .explanation {
        font-size: 12px;
        margin-bottom: 20px; }

    .chosen-attribute-options {
        padding: 10px 0 10px 10px;

        .existence-options {
            border-top: 1px solid #ddd;
            //background: yellow
            width: calc(100% - 10px);
            margin-top: 5px;
            padding-top: 5px;
            .radio-label {
                font-size: 13px;
                font-weight: 400; } }

        .MuiFormControlLabel-root {
            background: transparent;
            transition: background 0.3s;
            border-radius: 6px;
            &:hover {
                background: #EAF4FF;

                .radio-label {
                    .count {
                        background: #ACD2FF; } } } }

        .MuiFormControlLabel-label {
            display: inline-block; }

        .radio-label {
            display: flex;
            justify-content: space-between;
            width: 240px;

            .value {
                font-weight: 500; }

            .count {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background: #eee;
                font-size: 11px;
                min-width: 25px;
                padding: 4px;
                border-radius: 50%;
                margin-right: 10px;
                transition: background 0.3s; } } } }

.attributes-active-label {
    font-size: 12px;
    .label {
        font-size: 11px;
        font-weight: 400; } }
