.active-appointments-indicator {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 10px;
    background: #fff;
    width: 250px;
    box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.08);
    flex: 0 1 auto;

    .text {
        margin-left: 10px;
        .main-line {
            font-size: 17px; }
        .sub-line-button {
            text-transform: none;
            padding: 0; } } }
