.one-off-appointment-content {
    padding: 0 20px;
    .heading {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-size: 16px;
            font-weight: 500; }

        .manual-create {
            color: #888;

            .one-off-button {
                text-transform: none;
                color: #1A84FF;
                padding: 3px 3px;
                font-size: 13px; } } }

    .custom-appt-form {
        margin-left: 40px;

        .sub-title {
            margin-top: 20px;
            margin-bottom: 20px; }

        .appointment-name {
            width: 300px; }

        .form-row {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .label {
                display: inline-block;
                width: 60px;
                text-align: right;
                margin-right: 10px;
                font-size: 12px;
                font-weight: 300; }

            .use-button {
                width: 300px; } } } }
