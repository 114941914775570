.connect-card {
    display: flex;
    background: #fcfcfc;
    border: 1px solid #ccc;
    //overflow: hidden
    border-radius: 5px;
    box-sizing: border-box;
    transition: background 0.3s, box-shadow 0.3s;
    cursor: pointer;
    box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.0);

    &.feature-advertisement {
        background: #B9EAE0 !important;
        border-color: #74D4C2;
        cursor: pointer;

        .text {
            .title {
                margin-bottom: 0px; } }

        &:hover {
            .text {
                .title {
                    .star-wrapper, .edit-icon {
                        opacity: 0; } } } }

        .text {
            pointer-events: none; }

        &.selected, &:hover {
            background: #B9EAE0;
            border-color: #00B18F;
            box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.15); }

        .icon {
            opacity: 0.9;

            .advertisement-buttons {
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: space-around;
                background: rgba(255,255,255,0.5);

                .dismiss-button {
                    color: #777;
                    font-size: 11px;
                    font-weight: 400; }

                .dismiss-button, .learn-more-button {
                    padding: 4px 8px; }


                .learn-more-button {
                    color: #1A84FF;
                    font-size: 13px; } } }

        .text {
            .bottom-gradient, .bottom-gradient-hover {
                opacity: 0;
                background-image: linear-gradient(rgba(209,238,255, 0), rgba(209,238,255,0)); }

            .additional-advertisement-text {
                position: absolute;
                bottom: 4px;
                left: 50%;
                font-size: 12px;
                white-space: nowrap;
                transform: translateX(-50%);
                padding: 4px 10px 2px 8px;
                background: rgba(255,255,255,0.8);
                border-radius: 10px;
                color: #333;
                display: flex;
                align-items: center;
                border: 1px solid #ddd;

                .MuiSvgIcon-root {
                    font-size: 16px;
                    margin-right: 5px;
                    color: #DF4851; } } } }





    &.selected, &:hover {
        background: #D1EEFF;
        border-color: #1c84ff;
        box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.15);

        .text {
            .bottom-gradient {
                opacity: 0; }
            .bottom-gradient-hover {
                opacity: 1; } } }

    &:hover {
        .text {
            .title {
                .star-wrapper, .edit-icon {
                    opacity: 1; } } } }

    .icon {
        background: #ccc;
        width: 120px;
        height: 120px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        overflow: hidden;
        position: relative; }

    .text {
        width: calc(100% - 150px);
        height: calc(100% - 10px);
        margin: 10px 0 0 10px;
        overflow: hidden;
        position: relative;
        //background: yellow

        .bottom-gradient, .bottom-gradient-hover {
            position: absolute;
            bottom: 0;
            height: 30px;
            width: 100%;
            opacity: 1;
            transition: opacity 0.3s;
            background-image: linear-gradient(rgba(255,255,255, 0), rgba(255,255,255,1));
            pointer-events: none; }

        .bottom-gradient-hover {
            opacity: 0;
            background-image: linear-gradient(rgba(209,238,255, 0), rgba(209,238,255,1));
            pointer-events: none; }

        .title {
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;

            .targeted-patient-count {
                background: #00B18F;
                color: #fff;
                display: inline-block;
                padding: 2px 7px;
                border-radius: 50%;
                margin-right: 5px;
                font-weight: 500; }

            .edit-icon {
                color: #1A84FF;
                font-size: 20px;
                margin-right: 5px;
                opacity: 0; }

            .star-wrapper {
                display: inline-block;
                position: relative;
                background: transparent;
                height: 21px;
                border-radius: 50%;
                opacity: 0;
                .star-border {
                    color: #ccc; }
                .star-inner {
                    position: absolute;
                    color: #eee;
                    left: 0;
                    top: 1px; }

                &.starred {
                    opacity: 1;
                    .star-border {
                        color: #555555; }
                    .star-inner {
                        color: #ffff8b; } } } }


        .template-snippet {
            font-size: 12px;
            position: relative;
            height: 7.5em;
            line-height: 1.5em;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical; } } }

.template-variable {
    background: #eee;
    color: #000;
    border: 1px solid #ccc;
    padding: 1px 4px;
    border-radius: 4px; }



