.hour-and-minute-select {
    display: inline-flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 34px;
    transition: all 0.1s;
    background: #fff;

    &.disabled {
        pointer-events: none;
        background: #f5f5f5; }

    &:hover {
        border-color: #000; }

    .MuiSvgIcon-root {
        color: #888;
        margin: 0 5px; }

    .hour-select {
        margin: 0 5px;
        width: 70px; }

    .minute-select {
        width: 50px; }

    .MuiInput-underline:before {
        opacity: 0 !important; } }
