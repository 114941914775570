.full-pep-upgrade {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    padding: 20px;
    max-width: 860px;
    font-family: Roboto, sans-serif;

    .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {
            .main-line {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 5px; }
            .sub-line {
                font-size: 12px;
                color: #666; } } }

    .highlights-and-pricing {
        display: flex;

        .highlights {
            width: 220px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .highlight {
                display: flex;
                align-items: center;
                margin: 10px 0;

                .highlight-image {
                    width: 70px;
                    height: 70px; }

                .title {
                    margin-left: 10px;
                    font-size: 13px;
                    font-weight: 500;
                    color: #333; } } }

        .thank-you-message {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            width: 100%;
            font-size: 24px;
            min-height: 200px;

            .text {
                max-width: 390px;
                text-align: center;

                .main-line {
                    font-weight: 500;
                    font-size: 18px;
                    margin-bottom: 5px; }
                .sub-line {
                    font-size: 16px;
                    font-weight: 300; } } }

        .pricing {
            width: calc(100% - 220px);
            margin: 20px 0 0 10px;
            .price-heading {
                font-size: 17px;
                font-weight: 500;
                margin-bottom: 10px; }

            .price-options {
                display: flex;
                justify-content: space-between;
                //max-width: 520px
                .price-option {
                    flex: 1;
                    padding: 20px;
                    border: 1px solid #ddd;
                    border-radius: 10px;
                    box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
                    margin: 0 5px;
                    transition: all 0.3s;
                    font-size: 13px;
                    min-width: 200px;
                    cursor: pointer;

                    .amount {
                        font-size: 20px;
                        color: #53A1FC;
                        margin-bottom: 10px;
                        .dollars {
                            font-weight: 500;
                            color: #1A84FF; } }

                    .billing {
                        font-size: 15px;
                        font-weight: 400; }

                    .credit-card-amount {
                        color: #666;
                        margin: 10px 0; }


                    .mini-highlights {
                        .mini-highlight {
                            display: flex;
                            font-size: 13px;
                            line-height: 18px;
                            margin-bottom: 5px;

                            .MuiSvgIcon-root {
                                font-size: 18px;
                                color: #1A84FF;
                                margin-right: 3px; } } } } }

            .basic-messaging-disclaimer {
                display: flex;
                align-items: center;
                margin-top: 20px;
                font-size: 13px;

                .MuiSvgIcon-root {
                    color: #00B18F;
                    margin-right: 10px;
                    font-size: 32px; } }

            .buttons {
                display: flex;
                justify-content: flex-end;
                margin-top: 20px; } } } }

.confirm-upgrade-dialog {
    font-family: Roboto, sans-serif;

    .error-icon, .check-icon {
        font-size: 48px;
        margin-right: 20px;
        color: #F3616A; }

    .check-icon {
        color: #00B18F; }

    .upgrade-button {
        .upgrading-label {
            display: flex;
            align-items: center;

            .MuiCircularProgress-root {
                color: #aaa; } } }

    .MuiDialogActions-root {
        padding: 0 20px 15px 20px; }
    .MuiDialogContent-root {
        display: flex;
        align-items: center;

        .pep-image {
            width: 240px;
            margin-right: 10px; }

        .text {
            max-width: 400px;
            display: inline-flex;
            flex-direction: column;
            justify-content: space-around;
            > div {
                margin: 10px 0; } } } }
