.resolve-duplicate-patient-matches {

    .resolve-heading {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px; }

    .options-wrapper {
        .resolve-sub-heading {
            max-width: 650px;
            font-size: 13px; }

        .ReactVirtualized__Grid {
            outline: none;
            border: 1px solid #ddd; }

        .ReactVirtualized__Table__row {
            border-bottom: 1px solid #e6e6e6;
            outline: none;
            box-sizing: border-box;
            font-size: 13px;

            .ReactVirtualized__Table__rowColumn:first-of-type {
                margin-left: 0px; } }

        .multiple-match-row {
            display: flex;
            align-items: center;

            .left {
                width: 50px;
                background: linear-gradient(to right, #f8f8f8, #fff 50%, #f8f8f8);
                border-right: 1px solid #eee;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                color: #555555;
                font-size: 15px; }

            .patient-record-header {
                font-size: 14px;
                font-weight: 500;
                .patient-record {
                    display: flex;
                    border: 1px dashed #ccc;
                    margin-top: 5px;
                    border-right: 0;
                    color: #000;

                    .text-field-wrapper {
                        //border: 1px solid #eee
                        border-right: 1px dashed #ccc;
                        padding: 5px 10px; }

                    .MuiInput-underline.Mui-disabled:before {
                        border-bottom: 0; }

                    .MuiInputLabel-root.Mui-disabled {
                        color: #333; }
                    .MuiInputBase-root.Mui-disabled {
                        color: #000; } } }

            .patients-heading {
                font-size: 14px;
                font-weight: 500;
                margin: 10px 0; }

            .matching-patients {
                border: 1px solid #dddddd;
                border-bottom: 0;

                .match-row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 40px;
                    font-size: 13px;
                    border-bottom: 1px solid #ddd;
                    padding-right: 20px;

                    .check-and-name {
                        display: inline-flex;
                        align-items: center; }

                    &.odd {
                        background: #f8f8f8; } } } } }

    .resolve-actions {
        display: flex;
        justify-content: flex-end;
        margin: 10px 0;

        .MuiButtonBase-root {
            margin-left: 10px; } } }
