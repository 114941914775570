.conversations-dialog {
    .MuiDialog-paper {
        min-width: 800px;
        min-height: 600px;

        .loading-message {
            display: flex;
            align-items: center;
            position: absolute;
            left: 50%;
            top: 50%;
            color: #888;
            transform: translate(-50%, -50%);

            .loading-indicator {
                margin-right: 10px; } } } }
