.confirm-selected-patients-content {
    position: relative;

    .add-error-message {
        position: absolute;
        bottom: 0;
        left: 50%;
        background: #DF4851;
        display: flex;
        align-items: center;
        transform: translate(-50%, 0%);
        padding: 10px;
        color: #fff;
        box-shadow: 5px 5px 10px rgba(0,0,0,0.1);

        .MuiSvgIcon-root {
            font-size: 42px;
            margin-right: 10px; }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            white-space: nowrap;
            margin-right: 10px;
            .main-line {
                font-size: 14px;
                font-weight: 500; }

            .sub-line {
                font-size: 11px; } }

        .error-ok-button {
            color: #fff; } }

    .adding-patients-progress {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }


    .main-content-wrapper {

        &.disabled {
            pointer-events: none;
            opacity: 0.25; }



        .heading {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            height: 35px;

            .text {
                margin-left: 20px; } }

        .no-createable-patients {
            background: #fafafa;
            padding: 10px;
            border: 1px solid #ddd;
            .no-patients-heading {
                font-size: 15px;
                margin-bottom: 10px; }

            max-width: 600px; }

        .add-patients-section {
            margin-top: 20px;
            .table-heading {
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 20px; }

            .ReactVirtualized__Grid {
                outline: none;
                border: 1px solid #ddd; }

            .ReactVirtualized__Table__headerRow {
                border-bottom: 1px solid #ddd;
                box-shadow: 0px 1px 1px rgba(0,0,0,0.05);
                background: #fff !important; }

            .ReactVirtualized__Table__row {
                border-bottom: 1px solid #e6e6e6;
                height: 30px;
                outline: none;
                box-sizing: border-box;
                font-size: 13px; } }

        .actions {
            margin: 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .auto-capitalize-toggle {
                cursor: pointer;
                font-size: 12px;
                margin-right: 10px;

                .MuiCheckbox-root {
                    padding: 4px; } }

            .no-thanks-button {
                margin-right: 20px; } } }

    .loading-content {
        padding: 10px;
        .main-text-line {
            font-size: 15px;
            margin-top: 10px;
            font-weight: 500; } } }

.bulk-patient-finished-snackbar {
    .bulk-selection-finished-snackbar-content {
        display: flex;
        align-items: center;

        .MuiSvgIcon-root {
            font-size: 36px;
            color: #fff;
            margin-right: 10px; }

        .main-line {
            font-size: 15px;
            font-weight: 500; }
        .sub-line {
            font-size: 12px; } } }

.search-patient-list-error-snackbar {
    .MuiSnackbarContent-root {
        color: #000;
        background: #DF4851;

        .error-message {
            background: #DF4851;
            display: flex;
            align-items: center;
            color: #fff;

            .MuiSvgIcon-root {
                font-size: 42px;
                margin-right: 10px; }


            .text {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                white-space: nowrap;
                margin-right: 10px;
                .main-line {
                    font-size: 14px;
                    font-weight: 500; }

                .sub-line {
                    font-size: 11px; } }

            .error-ok-button {
                color: #fff; } } } }
